import React, { useState } from "react";
import axios from "axios";
import { API_ROOT } from "../../config/env-var";
import moment from "moment";

const CheckoutAside = ({ discount, setDiscount, event, ticket }) => {
  const [couponCode, setCouponCode] = useState("");

  const getTotal = () => {
    let total =
      ticket.fee -
      (discount
        ? discount.coupon_type_id === 1
          ? discount.coupon_value
          : ticket.fee * (discount.coupon_value / 100)
        : 0);

    total = total > 5 ? total : 5;
    return total;
  };

  const checkCoupon = async () => {
    console.log(event);
    let data = await axios
      .get(
        `${API_ROOT}/coupons?code=${couponCode}&date=${moment(
          new Date()
        ).format("YYYY-MM-DD")}&event_id=${event.id}`
      )
      .catch(console.log);
    console.log(data, data.data.results[0]);
    let validCoupon = data.data.results && data.data.results.length > 0;
    validCoupon ? setDiscount(data.data.results[0]) : setDiscount();
  };
  return (
    <aside>
      <div className="widget">
        <h4 className="fs-20 mb-20">Your Order</h4>
        {ticket && (
          <div className="bst-recipe-widget">
            <div className="d-flex bst-recipe-item align-items-start">
              <div>
                <h5 className="fs-25 font-weight-semibold mb-0">
                  {ticket.stream_name}
                </h5>
                <p>
                  <span className="fs-14 text-theme text-uppercase pb-2 d-block">
                    ${ticket.fee.toFixed(2)}
                  </span>
                  <span className="fs-14 text-danger text-uppercase pb-2 d-block">
                    {discount
                      ? `- $${
                          discount.coupon_type_id === 1
                            ? discount.coupon_value
                            : ticket.fee.toFixed(2) *
                              (discount.coupon_value / 100)
                        } (DISCOUNT APPLIED: ${discount.coupon_name})`
                      : ""}
                  </span>
                </p>

                <h3 className="fs-15 font-weight-semibold mb-0 text-danger">
                  Total: ${getTotal().toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
        )}
      </div>
      {ticket && (
        <div className="widget">
          <h4 className="fs-20 mb-20">Coupons</h4>
          <div className="bst-recipe-widget">
            <div className="d-flex bst-recipe-item align-items-start">
              <div>
                <input
                  className="form-control mb-20"
                  type="text"
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                  }}
                />
                <button
                  className="mt-20 theme-btn-secondary"
                  onClick={() => {
                    checkCoupon(couponCode);
                  }}
                >
                  Apply Coupon
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};
export default CheckoutAside;
