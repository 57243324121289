import React, { useState } from "react";
import { useAuthContext } from "../../context/auth-context";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";

function LoginArea() {
  const context = useAuthContext();
  const [showPassword, setShowPassword] = useState("");
  const [error, setError] = useState("");
  const initLoginValues = {
    email: "",
    password: "",
  };

  let history = useHistory();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("An email is required"),
    password: Yup.string().required("A password is required"),
  });

  return (
    <section className="login-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <h1 id="login-txt">Login</h1>
            <div className="login-info-wrap">
              <div className="register-form login-wrap">
                <Formik
                  enableReinitialize={true}
                  initialValues={initLoginValues}
                  validationSchema={loginSchema}
                  onSubmit={async (values) => {
                    try {
                      let login = await context.handleLogin(
                        values.email,
                        values.password,
                        setError
                      );
                      if (login) {
                        history.push("/enter-scores");
                      }
                    } catch {
                      console.log("login error");
                    }
                  }}
                >
                  {({ touched, errors }) => (
                    <Form className="login-form">
                      <div className="form-grp">
                        <div className="input-group">
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Your email..."
                          />
                          {errors["email"] && touched["email"] && (
                            <small className="text-danger">
                              {errors["email"].toString()}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="form-grp">
                        <div className="input-group">
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            className="form-control"
                            placeholder="Password"
                          />
                          <span
                            className="password-toggle eye-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={`fa ${
                                showPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                        {errors["password"] && touched["password"] && (
                          <small className="text-danger">
                            {errors["password"].toString()}
                          </small>
                        )}
                        {error !== "" && (
                          <div>
                            <small className="text-danger">{error}</small>
                          </div>
                        )}
                      </div>
                      {/* <div className="password-forgot">
                        <a href="/forgot-password">Forgot your password?</a>
                      </div> */}
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                      {/* <a href="/signup" className="text-btn" id="signup-btn">
                        Sign up
                      </a> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginArea;
