import React from 'react'
import LoginArea from '../component/auth/LoginArea'
// import ContactMap from '../components/contact/ContactMap.'
import Header from "../component/header/Header2";
import Footer from "../component/footer/Footer";

function login() {
  return (
   <>
    <Header/>
    <main>
        {/* <Breadcrumb/> */}
        <LoginArea/>
        {/* <ContactMap/> */}
    </main>
    {<Footer/>}
   </>
  )
}

export default login