import React from "react";
import { Button, Table } from "react-bootstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ScoreTable = ({
  scoreRowData,
  displayRange,
  displayCount,
  setDisplayRange,
  setDisplayCount,
  showDeleteModal,
}) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="text-muted text-center">
              <h5>#</h5>
            </th>
            <th className="text-muted text-center">
              <h5>Team Name</h5>
            </th>
            <th className="text-muted text-center">
              <h5>Round Number</h5>
            </th>
            <th className="text-muted text-center">
              <h5>Score</h5>
            </th>
            <th className="text-muted text-center">
              <h5>Time</h5>
            </th>
            <th className="text-muted text-center">
              <h5>Action</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          {scoreRowData &&
            scoreRowData.map((data, index) => {
              return (
                <tr key={index}>
                  <td>
                    <p className="text-center">
                      {data.event_id} - {data.team_score_id} -{" "}
                      {data.age_categories_name}
                    </p>
                  </td>
                  <td>
                    <p className="text-center">
                      {data.team_id} - {data.team_name}
                    </p>
                  </td>
                  <td>
                    <p className="text-center">{data.round_number}</p>
                  </td>
                  <td>
                    <p className="text-center">{data.score}</p>
                  </td>
                  <td>
                    <p className="text-center">{data.time}</p>
                  </td>
                  <td style={{ paddingLeft: "2%" }}>
                    <Button
                      className="btn btn-danger"
                      onClick={() => {
                        showDeleteModal(data.team_score_id);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};
export default ScoreTable;
