/* eslint-disable no-restricted-imports */
import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { API_ROOT } from "../../../../config/env-var";

export function DeleteScore({ showDelete, showDeleteModal, hideDeleteModal }) {
  console.log(showDelete);
  let history = useHistory();
  const deleteScorePermenantly = async () => {
    try {
      const res = await axios.delete(`${API_ROOT}/scores/${showDelete}`);
      console.log(res);
      hideDeleteModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={showDelete != false}
      onHide={hideDeleteModal}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Delete Score
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to delete this score?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            type="button"
            onClick={() => {
              hideDeleteModal();
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </Button>
          <> </>
          <Button
            type="button"
            onClick={deleteScorePermenantly}
            disabled={showDelete == false}
            className="btn btn-danger btn-elevate"
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
