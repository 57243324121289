import React, { createContext, useContext, useEffect, useState } from "react";
import { API_ROOT } from "../config/env-var";
import axios from "axios";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthConsumer = AuthContext.Consumer;

export function AuthContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [resetCode, setResetCode] = useState();
  const [user, setUser] = useState({});

  const handleLogin = async (userName, password, setError) => {
    // alert("Handling login");
    let response_data = await axios
      .post(`${API_ROOT}/auth/login`, {
        email: userName,
        password: password,
      })
      .catch(console.log);
    if (response_data && response_data.status == 200) {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      console.log(response_data);
      setUser(response_data.data.user);
      localStorage.setItem("user", JSON.stringify(response_data.data.user));
      return true;
    } else {
      setError(
        "Sorry, your password was incorrect. Please double-check your password."
      );
      return false;
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.setItem("isLoggedIn", JSON.stringify(false));
    localStorage.setItem("user", JSON.stringify({}));
    return true;
  };



  const authContextObj = {
    isLoggedIn: isLoggedIn,
    handleLogin: handleLogin,
    handleLogout: handleLogout,
    user: user,
    setUser: setUser,
  };

  useEffect(() => {
    const isLoggedInItem = JSON.parse(localStorage.getItem("isLoggedIn"));
    const userItem = JSON.parse(localStorage.getItem("user"));
    if (typeof isLoggedInItem != "undefined") {
      setIsLoggedIn(isLoggedInItem);
    } else {
      setIsLoggedIn(false);
    }
    if (userItem) {
      setUser(userItem);
    }
  }, []);

  return (
    <AuthContext.Provider value={authContextObj}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
