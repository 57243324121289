import axios from "axios";
import { API_ROOT } from "../../config/env-var";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
const PaymentSuccess = ({ location }) => {
  const [error, setError] = useState();
  const query = new URLSearchParams(location.search);

  const trans_id = query.get("stripe_trans_id");
  const converge_trans_id = query.get("ssl_txn_id");
  useEffect(() => {
    const sendConvergeId = async () => {
      const res = await axios.patch(`${API_ROOT}/checkout/` + trans_id, {
        converge_id: converge_trans_id,
      });

      if (res.data === "success") {
        const res_email = await axios.post(
          `${API_ROOT}/checkout/send_confirmation`,
          {
            transaction_id: trans_id,
          }
        );
        if (res_email.data !== "Email Sent") {
          alert(
            "Something went wrong. Please call the center to check if your transaction went through."
          );
          setError("Error Sending Email");
        }
      } else {
        alert(
          "Something went wrong. Please call the center to check if your transaction went through."
        );
        setError("Error Sending Email");
      }
    };

    sendConvergeId();
  }, [trans_id, converge_trans_id]);

  return (
    <>
      <section>
        <div className="error-area pt-40 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 text-center">
                <div>
                  {error != null ? (
                    <p className="text-danger"> {error}</p>
                  ) : (
                    <div>
                      <h1>Thank You!</h1>
                      <br />
                      <p>
                        Your payment was successful. You will receive an email
                        with details for the event you enrolled in. We are
                        thrilled to be a part of your STEM Competition journey.
                      </p>
                      <br />
                    </div>
                  )}
                  <p className=" alert alert-info text-left">
                    <b>S.T.R.I.P.E. Competition Transaction ID: </b> {trans_id}{" "}
                    <br />
                    <b>Payment Transaction ID: </b> {converge_trans_id}{" "}
                  </p>
                  <br />
                  <Link to={process.env.PUBLIC_URL + "/"} className="error-btn">
                    Back to home page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentSuccess;
