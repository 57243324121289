import React, { Fragment } from "react";
import Header from "../../../header/Header2";
import Footer from "../../../footer/Footer";
import Venue2023 from "./Venue2023";
import Sponsors2023 from "./Sponsors2023";
import EndOfSeason from "../../../Common/EndOfSeason";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import PastChallenges from "../../../Common/PastChallenges";
import Banner from "../../../header/Banner";



const S2023HomePage = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>2023 Season</title>
        <meta
          name="description"
          content="Competition, In-Person Competition, STEM, Coding, Programming, Kids,
          Robotics, Java, Python, Canada, USA, Mississauga, After school program, 
          Robotics Club, Milton, Cary, Arduino, Technology,
          Burlington, Toronto, Danforth, Brampton, Raleigh, Mount Olive, Mt. Olive"
        />
        <link rel="canonical" href="https://www.stripecompetition.com/2023season" />
      </MetaTags>
      <Header />
        <Banner link="2023season" title="2023 Season" />

      {/* End Of Season */}
      <section>
        <EndOfSeason />
      </section>
      {/* Challenges */}
      <section>
        <PastChallenges/>
      </section>
      {/* Venue Info */}
      <section>
        <Venue2023 />
      </section>
      {/* Sponsors */}
      <section>
        <Sponsors2023/>
      </section>

      {/* <VideoSection /> */}
      <Footer />
    </Fragment>
  );
};
export default S2023HomePage;
