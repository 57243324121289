import React from "react";

const ScoreboardFilters = ({ eventList, eventIndex, setEventIndex }) => {
  return (
    <>
      <div className="row mb-20 mt-20">
        <div className="col-lg-6">
          <label>
            <b>Event</b>
          </label>
          <select
            className={"form-control"}
            onChange={(e) => {
              setEventIndex(e.target.value);
            }}
          >
            <option key={-1} value={-1}>
              Choose...
            </option>
            {eventList.map((e, eI) => {
              return (
                <option key={eI} value={e.id}>
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
};
export default ScoreboardFilters;
