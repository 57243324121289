import axios from "axios";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import uuid from "uuid/v4";
import * as Yup from "yup";
import { API_ROOT } from "../../config/env-var";
import { InputWithLimit } from "../Common/InputWithLimit";

const getFormattedNumber = (value) => {
  value = value.replace(/[^\d]/g, "");
  if (value.length <= 0) {
    return ``;
  } else if (value.length < 4) {
    return `(${value}`;
  } else if (value.length < 7) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  } else {
    return `(${value.slice(0, 3)}) - ${value.slice(3, 6)} - ${
      value.length > 10 ? value.slice(6, 10) : value.slice(6)
    }`;
  }
};

export const trimOffTheTop = (value, extraSpaceReplacement) => {
  let extraSpaces = /[[:space:]]{2,}|\t/g;
  return value.replaceAll(extraSpaces, extraSpaceReplacement).trim();
};

const getVanillaPhoneNumber = (value) => {
  return (
    value.substring(1, 4) + value.substring(8, 11) + value.substring(14, 18)
  );
};

const ParentForm = ({
  errors,
  values,
  touched,
  setValues,
  getFieldProps,
  setFieldValue,
  refferalValues,
}) => {
  return (
    <div className="checkout-section">
      <div className="fs-26 about-text">
        <h2 className="text-uppercase  position-relative">
          Coach/Parent Information
        </h2>
      </div>{" "}
      <div className="about-text">
        <p>Provide information for the key contact for this team. </p>
      </div>
      <div className="row">
        <div className="col-md-6">
          <InputWithLimit
            label="First Name"
            name={`parent_firstname`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_firstname}
            errors={errors.parent_firstname}
            value={values.parent_firstname}
          />
        </div>
        <div className="col-md-6">
          <InputWithLimit
            label="Last Name"
            name={`parent_lastname`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_lastname}
            errors={errors.parent_lastname}
            value={values.parent_lastname}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputWithLimit
            label="Address 1"
            name={`parent_address1`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_address1}
            errors={errors.parent_address1}
            value={values.parent_address1}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputWithLimit
            label="Address 2"
            name={`parent_address2`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_address2}
            errors={errors.parent_address2}
            value={values.parent_address2}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <InputWithLimit
            label="City"
            name={`parent_city`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_city}
            errors={errors.parent_city}
            value={values.parent_city}
          />
        </div>
        <div className="col-md-4">
          <InputWithLimit
            label="State/Province"
            name={`parent_state`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_state}
            errors={errors.parent_state}
            value={values.parent_state}
          />
        </div>
        <div className="col-md-4">
          <InputWithLimit
            label="Zip/Postal Code"
            name={`parent_zip`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_zip}
            errors={errors.parent_zip}
            value={values.parent_zip}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputWithLimit
            label="Country"
            name={`parent_country`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_country}
            errors={errors.parent_country}
            value={values.parent_country}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputWithLimit
            label="Email"
            name={`parent_email`}
            type="email"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_email}
            errors={errors.parent_email}
            value={values.parent_email}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputWithLimit
            label="Phone"
            name={`parent_phone`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.parent_phone}
            errors={errors.parent_phone}
            {...getFieldProps("parent_phone")}
            onChange={(e) => {
              let phoneNumber = getFormattedNumber(e.target.value);
              setFieldValue("parent_phone", phoneNumber);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label>How did you hear about us?</label>
          <Field name="refferal">
            {({ field }) => (
              <select
                {...field}
                className={
                  "form-control" +
                  (errors.refferal && touched.refferal ? " is-invalid" : "")
                }
              >
                <option key={-1} value={""}>
                  Choose...
                </option>
                {refferalValues.map((val, i) => (
                  <option key={i} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            )}
          </Field>
          <ErrorMessage
            name="refferal"
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    </div>
  );
};

/*

const StudentForm = ({
  errors,
  values,
  touched,
  setValues,
  getFieldProps,
  setFieldValue,
}) => {
  return (
    <div className="checkout-section">
      <div className="fs-26 about-text">
        <h2 className="text-uppercase  position-relative">
          Student Information
        </h2>
      </div>{" "}
      <div className="row">
        <div className="col-md-6">
          <InputWithLimit
            label="First Name"
            name={`student_firstname`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.student_firstname}
            errors={errors.student_firstname}
            value={values.student_firstname}
          />
        </div>
        <div className="col-md-6">
          <InputWithLimit
            label="Last Name"
            name={`student_lastname`}
            type="text"
            maxLength={100}
            displayLimit={false}
            touched={touched.student_lastname}
            errors={errors.student_lastname}
            value={values.student_lastname}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <InputWithLimit
            label="Date of Birth"
            name={`student_dob`}
            type="date"
            displayLimit={false}
            touched={touched.student_dob}
            errors={errors.student_dob}
            value={values.student_dob}
          />
        </div>
      </div>
    </div>
  );
};

*/

const TeamForm = ({
  errors,
  values,
  touched,
  setValues,
  getFieldProps,
  setFieldValue,
  ticket,
}) => {
  // const [numStudents, setNumStudents] = useState(0);
  const [ageGroup, setAgeGroup] = useState(-1);
  const [timeSlot, setTimeSlot] = useState(-1);

  function onChangeStudents(e, field, values, setValues) {
    // update dynamic form
    const team = [...values.team];
    const numberOfStudents = e.target.value || 0;
    const previousNumber = parseInt(field.value || "0");
    if (previousNumber < numberOfStudents) {
      for (let i = previousNumber; i < numberOfStudents; i++) {
        team.push({
          student_firstname: "",
          student_lastname: "",
          student_dob: "",
        });
      }
    } else {
      for (let i = previousNumber; i >= numberOfStudents; i--) {
        team.splice(i, 1);
      }
    }

    setValues({ ...values, team });
    // setNumStudents(numberOfStudents);
    // call formik onChange method
    field.onChange(e);
  }
  return (
    <div className="checkout-section">
      <div className="fs-26 about-text">
        <h2 className="text-uppercase  position-relative">Team Information</h2>
      </div>{" "}
      <div className="row">
        <div className="col-md-12">
          <label>Age Group</label>
          <Field name="age_category">
            {({ field }) => (
              <select
                {...field}
                onChange={(e) => {
                  setAgeGroup(e.target.value);
                  setFieldValue("age_category", e.target.value);
                  if (ticket.slots[e.target.value].timings.length === 1) {
                    setTimeSlot(0);
                    setFieldValue("time_slot", 0);
                  }
                }}
                className={
                  "form-control" +
                  (errors.age_category && touched.age_category
                    ? " is-invalid"
                    : "")
                }
              >
                <option key={-1} value={-1}>
                  {""}
                </option>
                {ticket.slots.map((age, i) => {
                  return (
                    <option key={i} value={i}>
                      {age.label}
                    </option>
                  );
                })}
              </select>
            )}
          </Field>
          <ErrorMessage
            name="age_category"
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
      {ageGroup !== -1 && (
        <div className="row">
          <div className="col-md-12">
            <label>Select Your Time Slot</label>
            <Field name="time_slot">
              {({ field }) => (
                <select
                  {...field}
                  onChange={(e) => {
                    setTimeSlot(e.target.value);
                    setFieldValue("time_slot", e.target.value);
                  }}
                  className={
                    "form-control" +
                    (errors.time_slot && touched.time_slot ? " is-invalid" : "")
                  }
                  value={timeSlot}
                >
                  <option key={-1} value={-1}>
                    {""}
                  </option>
                  {ticket.slots[ageGroup].timings.map((s, i) => {
                    return (
                      <option key={i} value={i}>
                        {moment(s.start_time, "HH:mm:ss").format("hh:mm a") +
                          " - " +
                          moment(s.end_time, "HH:mm:ss").format("hh:mm a")}
                      </option>
                    );
                  })}
                </select>
              )}
            </Field>
            <ErrorMessage
              name="time_slot"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
      )}
      {ageGroup !== -1 && timeSlot !== -1 && (
        <div className="row">
          <div className="col-md-12">
            <label>Number of Students</label>
            <Field name="numberOfStudents">
              {({ field }) => (
                <select
                  {...field}
                  className={
                    "form-control" +
                    (errors.numberOfStudents && touched.numberOfStudents
                      ? " is-invalid"
                      : "")
                  }
                  onChange={(e) =>
                    onChangeStudents(e, field, values, setValues)
                  }
                >
                  <option key={-1} value={-1}>
                    {" "}
                  </option>
                  {(ticket.slots[ageGroup].timings[timeSlot].team_ind === 0
                    ? [1]
                    : [2, 3]
                  ).map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              )}
            </Field>
            <ErrorMessage
              name="numberOfStudents"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
      )}
      {/*ticket.name == "Robotics - Autonomous Challenge" &&
        values.age_category &&
        values.age_category !== "Nebula (7 - 9 yrs)" && (
          <>
            <div className="row">
              <div className="col-md-12">
                <label>Select Your Robot</label>
                <Field name="robot">
                  {({ field }) => (
                    <select
                      {...field}
                      className={
                        "form-control" +
                        (errors.robot && touched.robot ? " is-invalid" : "")
                      }
                    >
                      {robotValues.map((value, i) => (
                        <option key={i} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  )}
                </Field>
                <ErrorMessage
                  name="robot"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <br />
          </>
                      )*/}
      <br />
      <FieldArray name="team">
        {() => {
          return (
            !isNaN(parseInt(values.numberOfStudents)) &&
            parseInt(values.numberOfStudents) > 0 &&
            ageGroup !== "" &&
            values.team.map((student, i) => {
              const studentErrors = errors.team?.length || {};
              const studentTouched = touched.team?.length || {};
              return (
                <>
                  <div key={i} className="row">
                    <h5>Team Member {i + 1}</h5>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <InputWithLimit
                        label="First Name"
                        name={`team.${i}.student_firstname`}
                        type="text"
                        maxLength={50}
                        displayLimit={false}
                        touched={studentTouched.student_firstname}
                        errors={studentErrors.student_firstname}
                        value={values.team[i].student_firstname}
                        className={
                          "form-control" +
                          (errors &&
                          errors.team &&
                          errors.team.length > i &&
                          errors.team[i] &&
                          errors.team[i].student_firstname &&
                          touched &&
                          touched.team &&
                          touched.team.length > i &&
                          touched.team[i] &&
                          touched.team[i].student_firstname
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                    <div className="form-group col-6">
                      <InputWithLimit
                        label="Last Name"
                        name={`team.${i}.student_lastname`}
                        type="text"
                        maxLength={50}
                        displayLimit={false}
                        touched={studentTouched.student_lastname}
                        errors={studentErrors.student_lastname}
                        value={values.team[i].student_lastname}
                        className={
                          "form-control" +
                          (errors &&
                          errors.team &&
                          errors.team.length > i &&
                          errors.team[i] &&
                          errors.team[i].student_lastname &&
                          touched &&
                          touched.team &&
                          touched.team.length > i &&
                          touched.team[i] &&
                          touched.team[i].student_lastname
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label>Date of Birth</label>
                      <Field
                        name={`team.${i}.student_dob`}
                        type="date"
                        className={
                          "form-control" +
                          (errors &&
                          errors.team &&
                          errors.team.length > i &&
                          errors.team[i] &&
                          errors.team[i].student_dob &&
                          touched &&
                          touched.team &&
                          touched.team.length > i &&
                          touched.team[i] &&
                          touched.team[i].student_dob
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name={`team.${i}.student_dob`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group col-6">
                      <InputWithLimit
                        label="School"
                        name={`team.${i}.school`}
                        type="text"
                        maxLength={50}
                        displayLimit={false}
                        touched={studentTouched.school}
                        errors={studentErrors.school}
                        value={values.team[i].school}
                        className={
                          "form-control" +
                          (errors &&
                          errors.team &&
                          errors.team.length > i &&
                          errors.team[i] &&
                          errors.team[i].school &&
                          touched &&
                          touched.team &&
                          touched.team.length > i &&
                          touched.team[i] &&
                          touched.team[i].school
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                  </div>
                  {ticket.team === 0 && (
                    <div className="row">
                      <div className="form-group col-12">
                        <InputWithLimit
                          label="Requested Team Members"
                          name={`team.${i}.requested_members`}
                          type="text"
                          maxLength={50}
                          displayLimit={false}
                          touched={studentTouched.requested_members}
                          errors={studentErrors.requested_members}
                          value={values.team[i].requested_members}
                          className={
                            "form-control" +
                            (errors &&
                            errors.team &&
                            errors.team.length > i &&
                            errors.team[i] &&
                            errors.team[i].requested_members &&
                            touched &&
                            touched.team &&
                            touched.team.length > i &&
                            touched.team[i] &&
                            touched.team[i].requested_members
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              );
            })
          );
        }}
      </FieldArray>
    </div>
  );
};

const PolicyAndConsent = ({
  errors,
  values,
  touched,
  setValues,
  getFieldProps,
  setFieldValue,
  uuidState,
}) => {
  return (
    <div className="checkout-section">
      <div className="fs-26 about-text">
        <h2 className="text-uppercase  position-relative">Policies</h2>
      </div>{" "}
      <div className="row">
        <div className="col-lg-12">
          <label htmlFor="policy_consent">
            <Field
              name="policy_consent"
              id="policy_consent"
              type="checkbox"
              checked={values.policy_consent}
              className={
                errors.policy_consent && touched.policy_consent
                  ? "form-control is-invalid"
                  : ""
              }
              style={{
                height: "20px",
                width: "20px",
                marginRight: "10px",
                display: "inline-block",
              }}
            />
            I have read and accept the{" "}
            <a href="/policies">
              {" "}
              <u> Media Release and Other Policies</u>
            </a>{" "}
            as outlined in the link provided. By submitting, I permit
            S.T.R.I.P.E Competition to contact me by email, phone or text. Rest
            assured that we respect your privacy, and we will only use your info
            to contact you about us.
            <br />
            <br />
            You will be transferred to our payment processing system to complete
            your transaction. The payment processing is handled by our Sponsor
            Zebra Robotics. Once the registration is complete, you will receive
            your confirmation email(s). Thank you!
            <ErrorMessage
              name={`policy_consent`}
              component="div"
              className="invalid-feedback"
            />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-8">
          <label>
            <b>Please enter the code to verify you are not a bot:</b>
            <b className="text-primary"> {uuidState.key}</b>
          </label>
        </div>
        <div className="form-group col-4">
          <Field
            name={`uuidkey`}
            type="text"
            className={
              "form-control" +
              (errors.uuidkey && touched.uuidkey ? " is-invalid" : "")
            }
          />
          <ErrorMessage
            name={`uuidkey`}
            component="div"
            className="invalid-feedback"
          />
          <p className="small text-danger">
            {!errors.uuidkey &&
            touched.uuidkey &&
            values.uuidkey !== uuidState.key
              ? "Please enter a valid code"
              : ""}
          </p>
        </div>
      </div>
      <button type="submit" className="btn btn-primary mr-1">
        Pay Now
      </button>
    </div>
  );
};

const CheckoutForm = ({ ticket, event, convergeCheckout }) => {
  console.log("Form", ticket);
  const [uuidState, setUuid] = useState(0);
  const refferalValues = [
    "Google Search",
    "Friends and Family",
    "Social Media",
    "Drive Thru",
    "Company Email",
    "Other",
  ];
  const getValidationSchema = () => {
    return Yup.object().shape({
      parent_firstname: Yup.string()
        .required("Parent first name is required")
        .test(
          "valid-text",
          "Parent first name is required",
          (value, context) => {
            return value && value.trim().length > 0;
          }
        ),
      parent_lastname: Yup.string()
        .required("Parent last name is required")
        .test(
          "valid-text",
          "Parent last name is required",
          (value, context) => {
            return value && value.trim().length > 0;
          }
        ),
      parent_address1: Yup.string()
        .required("Parent Address is required")
        .test("valid-text", "Parent address is required", (value, context) => {
          return value && value.trim().length > 0;
        }),
      parent_zip: Yup.string()
        .required("Parent zip is required")
        .test("valid-text", "Parent zip is required", (value, context) => {
          return value && value.trim().length > 0;
        }),
      parent_state: Yup.string()
        .required("Parent state is required")
        .test("valid-text", "Parent state is required", (value, context) => {
          return value && value.trim().length > 0;
        }),
      parent_city: Yup.string()
        .required("Parent city is required")
        .test("valid-text", "Parent city is required", (value, context) => {
          return value && value.trim().length > 0;
        }),
      parent_country: Yup.string()
        .required("Parent country is required")
        .test("valid-text", "Parent country is required", (value, context) => {
          return value && value.trim().length > 0;
        }),
      parent_email: Yup.string()
        .email("Must be an email address")
        .required("Email is required"),
      parent_phone: Yup.string()
        .required("Parent phone is required")
        .test(
          "valid-number",
          "Sorry, only numbers (0-9) are allowed",
          (value, context) => {
            return (
              value &&
              value.split("@").length > 0 &&
              value.split("@")[0].match(/[(0-9)(\-)(()())( ))]*/g)[0] ===
                value.split("@")[0]
            );
          }
        ),
      refferal: Yup.string()
        .required("How did you hear about us is required")
        .oneOf(refferalValues),
      numberOfStudents: Yup.string()
        .required("Number of students is required")
        .test(
          "valid-size",
          "Please select number of memebers",
          (value, context) => {
            return !isNaN(parseInt(value)) && parseInt(value) > 0;
          }
        ),
      team: Yup.array().of(
        Yup.object().shape({
          student_firstname: Yup.string()
            .required("Student first name is required")
            .test(
              "valid-text",
              "Student first name is required",
              (value, context) => {
                return value && value.trim().length > 0;
              }
            ),
          student_lastname: Yup.string()
            .required("Student last name is required")
            .test(
              "valid-text",
              "Student last name is required",
              (value, context) => {
                return value && value.trim().length > 0;
              }
            ),
          student_dob: Yup.string()
            .required("Student date of birth is required")
            .test(
              "valid-text",
              "Student date of birth is required",
              (value, context) => {
                return value && value.trim().length > 0;
              }
            ),
          school: Yup.string()
            .required("Student school is required")
            .test(
              "valid-text",
              "Student school is required",
              (value, context) => {
                return value && value.trim().length > 0;
              }
            ),
          requested_members: Yup.string(),
        })
      ),
      age_category: Yup.string().required("Please select an age group"),
      time_slot: Yup.string().required("Please select a time slot"),

      policy_consent: Yup.boolean()
        .required("Required")
        .oneOf([true], "You must accept the terms and conditions."),
      uuidkey: Yup.string("Please enter the code")
        .min(4, "Please enter the code")
        .required("Please enter the code"),
      robot: Yup.string("Please Select Your Robot"),
      // ticket.name == "Robotics - Autonomous Challenge"
      // ? Yup.string("Please Select Your Robot")
      // .when("age_category", {
      //     is: (value) => value !== "Nebula (7 - 9 yrs)",
      //     then: (schema) => schema.required("Please Select Your Robot"),
      //     otherwise: (schema) => schema,
      //   })
      // : Yup.string("Cool, please ignore"),
    });
  };
  const initialValues = {
    parent_firstname: "",
    parent_lastname: "",
    parent_address1: "",
    parent_address2: "",
    parent_zip: "",
    parent_state: "",
    parent_city: "",
    parent_country: "",
    parent_email: "",
    parent_phone: "",
    refferal: "",
    time_slot: "",
    team: [],
    policy_consent: false,
    numberOfStudents: 0,
    robot: "",
  };
  // const [teamInd, setTeamInd] = useState(false);

  const postTransactionUUID = async () => {
    if (uuidState === 0) {
      let currUuid = uuid();

      let keyValue = {
        key: currUuid.slice(0, 4),
        value: currUuid.slice(4, currUuid.length),
      };
      setUuid(keyValue);
      await axios.post(`${API_ROOT}/transactions/uuid`, keyValue);
    }
  };

  useEffect(() => {
    postTransactionUUID();
  });

  async function onSubmit(fields) {
    let studentData = fields.team.map((student) => {
      return {
        student_firstname: trimOffTheTop(student.student_firstname, " "),
        student_lastname: trimOffTheTop(student.student_lastname, " "),
        student_dob: student.student_dob,
        student_school: student.school,
        requested_members: student.requested_members
          ? trimOffTheTop(student.requested_members, " ")
          : "",
      };
    });

    console.log("Event let on", ticket, event);

    let transactionData = {
      parent_firstname: trimOffTheTop(fields.parent_firstname, " "),
      parent_lastname: trimOffTheTop(fields.parent_lastname, " "),
      parent_email: trimOffTheTop(fields.parent_email, ""),
      parent_phone: getVanillaPhoneNumber(fields.parent_phone),
      parent_address1: trimOffTheTop(fields.parent_address1, " "),
      parent_address2: trimOffTheTop(fields.parent_address2, " "),
      parent_country: fields.parent_country,
      parent_city: trimOffTheTop(fields.parent_city, " "),
      parent_state: trimOffTheTop(fields.parent_state, " "),
      parent_zip: trimOffTheTop(fields.parent_zip, " ").replaceAll("-", ""),
      refferal: trimOffTheTop(fields.refferal, " "),
      age_category: ticket.slots[fields.age_category].value,
      team: studentData,
      total: ticket.fee.toFixed(2),
      time_slot:
        moment(
          ticket.slots[fields.age_category].timings[fields.time_slot]
            .start_time,
          "HH:mm:ss"
        ).format("hh:mm a") +
        " - " +
        moment(
          ticket.slots[fields.age_category].timings[fields.time_slot].end_time,
          "HH:mm:ss"
        ).format("hh:mm a"),
      ticket_title: ticket.stream_name,
      robot: trimOffTheTop(fields.robot, " "),
      event_id: ticket.event_id,
    };
    console.log(transactionData);
    try {
      const res = await axios.post(`${API_ROOT}/checkout/`, transactionData);
      res.data !== "failed" &&
        convergeCheckout(
          res.data,
          transactionData.parent_firstname,
          transactionData.parent_lastname,
          transactionData.parent_email,
          transactionData.parent_phone,
          transactionData.parent_address1,
          transactionData.parent_address2,
          transactionData.parent_country,
          transactionData.parent_city,
          transactionData.parent_state,
          transactionData.parent_zip,
          transactionData.parent_country.toLowerCase().includes("canada")
            ? "CAN"
            : transactionData.parent_country,
          fields.uuidkey,
          uuidState.key,
          uuidState.value,
          ticket.fee
        );
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          touched,
          setValues,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form style={{ width: "90%", marginTop: "-35px" }}>
            <div className="checkout-section">
              <div className="ticket-header mb-40">
                <h3 className="">{event && event.name}</h3>
              </div>
              <p className="text-center">
                If you don't have a team already, please reach out to your local{" "}
                <a className="text-theme" href="https://zebrarobotics.com/">
                  Zebra Robotics
                </a>{" "}
                or your school to help form a team
              </p>
            </div>
            <ParentForm
              errors={errors}
              values={values}
              touched={touched}
              setValues={setValues}
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              refferalValues={refferalValues}
            />
            <TeamForm
              errors={errors}
              values={values}
              touched={touched}
              setValues={setValues}
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              ticket={ticket}
            />
            <PolicyAndConsent
              errors={errors}
              values={values}
              touched={touched}
              setValues={setValues}
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              uuidState={uuidState}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
export default CheckoutForm;
