import React, { useEffect, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context";

const Header2 = () => {
  const [menuTrigger, setMenuTrigger] = useState(false);
  const [closeMenuTrigger, setCloseMenuTrigger] = useState(false);

  let context = useAuthContext();

  useEffect(() => {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function (e) {
          e.preventDefault();
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.parentElement.classList.toggle("active");
        };
      }
    }
  }, []);

  const handleMenuTrigger = () => {
    setMenuTrigger(!menuTrigger);
    document.querySelector(".nav-menu").classList.toggle("active");
  };
  const handleCloseMenuTrigger = () => {
    setCloseMenuTrigger(!closeMenuTrigger);
    document.querySelector(".nav-menu").classList.remove("active");
  };

  return (
    <header className="style1 style2 position-relative">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="logo">
            <a className="navbar-brand" href="/">
              <img src="assets/images/stripe_logo.png" alt="" />
            </a>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
            <span onClick={handleMenuTrigger} className="menutrigger">
              <FiMenu />
            </span>
          </div>
          <div className="nav-menu">
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={handleCloseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
            {/* <!-- main menu --> */}
            <div className="header-wrapper" id="header-wrapper">
              <ul className="main-menu ">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="home-drop option has-droupdown">
                  <a href="/" className="home-click">
                    Streams
                  </a>
                  <ul className="submenu">
                    <li>
                      <Link to="/Robotics">Robotics</Link>
                    </li>
                    {/* <li>
                      <Link to="/Innovation">Innovation</Link>
                    </li> */}
                    <li>
                      <Link to="/Programming">Programming</Link>
                    </li>
                  </ul>
                </li>
                <li className="home-drop option has-droupdown">
                  <a href="/Sponsor" className="home-click">
                    Get Involved
                  </a>
                  <ul className="submenu">
                    {/* <li>
                      <a
                        target="_blank"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdPzRbWNZWd3unPl11Aw47tZADd_5JkQuHvTn3oScE7QbeaHg/viewform"
                      >
                        Volunteer
                      </a>
                    </li> */}
                    <li>
                      <Link to="/Sponsor">Sponsor</Link>
                    </li>
                  </ul>
                </li>
                <li className="home-drop option has-droupdown">
                  <a href="/About" className="home-click">
                    About
                  </a>
                  <ul className="submenu">
                    <li>
                      <a href="/About"> About Us</a>
                    </li>
                    <li>
                      <a href="/Ethics"> Ethics</a>
                    </li>
                  </ul>
                </li>
                <li className="home-drop option has-droupdown">
                  <a href="/2024season" className="home-click">
                    Seasons
                  </a>
                  <ul className="submenu">
                    <li>
                      <a href="/2024season"> 2023-2024 Season</a>
                    </li>
                    <li>
                      <a href="/2023season"> 2023 Season</a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="site-menu-btns">
                {context.isLoggedIn ? (
                  <a className="theme-btn-secondary" href="/enter-scores">
                    Scores<span></span>
                  </a>
                ) : (
                  <a className="theme-btn-secondary" href="/2024season">
                    Register<span></span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="site-menu-wrapper collapse navbar-collapse">
            <ul className="navbar-nav">
              <li className="nav-item nav-underline-hover">
                <Link to="/">Home</Link>
              </li>
              <li className="nav-item dropdown position-relative">
                <a
                  className="nav-link"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Streams
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="/Robotics">
                      {" "}
                      Robotics
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/innovation">
                      {" "}
                      Innovation
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/Programming">
                      {" "}
                      Programming
                    </Link>
                  </li>
                </ul>
              </li>
              {/*
                <li className="nav-item">
                  <Link to="/ChallengeListView">
                    2023 Season
                  </Link>
                </li>
                */}

              <li className="nav-item dropdown position-relative">
                <a
                  className="nav-link"
                  href="/Sponsor"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Get Involved
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  {/* <li>
                    <a
                      className="dropdown-item"
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdPzRbWNZWd3unPl11Aw47tZADd_5JkQuHvTn3oScE7QbeaHg/viewform"
                    >
                      {" "}
                      Volunteer
                    </a>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/Sponsor">
                      {" "}
                      Sponsor
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown position-relative">
                <a
                  className="nav-link"
                  href="/2024season"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Seasons
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="/2024season">
                      <p>
                        Alien Planet{" "}
                        <span className="nav-item-small-text">
                          2023-2024 Season
                        </span>
                      </p>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/2023season">
                      {" "}
                      <p>
                        Clean Water Sustainability{" "}
                        <span className="nav-item-small-text">2023 Season</span>
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown position-relative">
                <a
                  className="nav-link"
                  href="/About"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="/About">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Ethics">
                      {" "}
                      Ethics
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item nav-underline-hover">
                <a href="/faq">FAQ</a>
              </li>
            </ul>
            <div className="site-menu-btns">
              {/* <a
                className="theme-btn-secondary"
                href={release ? "/score" : "/#"}
                title=""
              >
                2023 Season Results<span></span>
              </a> */}
              {context.isLoggedIn ? (
                <a className="theme-btn-secondary" href="/enter-scores">
                  Scores<span></span>
                </a>
              ) : (
                <a className="theme-btn-secondary" href="/2024season">
                  Register<span></span>
                </a>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2;
