import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { API_ROOT } from "../../../../config/env-var";

const Divisions2024 = ({ bgClass }) => {
  const [tournaments, setTournaments] = useState([]);

  const fetchTournaments = async () => {
    let tournaments = await axios
      .get(`${API_ROOT}/tournaments`)
      .catch(console.log);
    console.log(tournaments.data);
    setTournaments(tournaments.data);
  };

  const getButton = (event) => {
    console.log(
      event,
      event.registration_end_date !== "N/A",
      moment(new Date()).isAfter(
        moment(`${event.registration_end_date} 23:59:00.000`)
      ),
      moment(new Date()).isBefore(moment(`${event.startdate} 23:59:00.000`))
    );
    if (
      event.registration_end_date === "N/A" &&
      !moment(new Date()).isAfter(moment(`${event.startdate} 23:59:00.000`))
    ) {
      return (
        <a className="theme-btn-muted" href={`#`}>
          Coming Soon<span></span>
        </a>
      );
    } else if (
      event.registration_end_date !== "N/A" &&
      moment(new Date()).isAfter(
        moment(`${event.registration_end_date} 23:59:00.000`)
      ) &&
      moment(new Date()).isBefore(moment(`${event.startdate} 23:59:00.000`))
    ) {
      return (
        <a className="theme-btn-muted" href={`/score`}>
          Registration Closed<span></span>
        </a>
      );
    } else if (
      event.registration_end_date !== "N/A" &&
      moment(new Date()).isBefore(
        moment(`${event.registration_end_date} 23:59:00.000`)
      ) &&
      moment(new Date()).isBefore(moment(`${event.startdate} 23:59:00.000`))
    ) {
      return (
        <a
          className="theme-btn-secondary"
          href={`/tickets?event_id=${event.id}`}
        >
          Registration<span></span>
        </a>
      );
    } else {
      return (
        <a className="theme-btn-secondary" href={`/score`}>
          Score<span></span>
        </a>
      );
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, []);

  return (
    <div className={`${bgClass} mt-80 mb-80`}>
      <div className="container">
        <div className="row">
          <div className="sec-heading text-center">
            <h2 className="fs-45">Division Tournaments</h2>
          </div>
          <p>
            Division tournaments give students an opportunity to hone their
            skills with a series of competitions, one in the fall and one in the
            winter. Division tournaments allow competitors to learn from their
            mistakes, make improvements, and feel encouraged to try out new
            problem-solving strategies. Competitors earn points for success in
            each division tournament that leads to a division award in the
            summer championship tournament.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-5 col-sm-12 mb-20">
            <h3 className="mb-40 text-center">
              <img className="venue-photo" src="assets/images/canada.png" />
              Canada{" "}
            </h3>
            <div className="event-list bg-light-yellow">
              {tournaments &&
                tournaments
                  .filter((t) => {
                    return t.championship_ind == 0 && t.country == "Canada";
                  })
                  .map((t) => {
                    return (
                      <div className="row mt-10">
                        <Card className="event-card">
                          <Card.Body>
                            <Card.Title>{t.name}</Card.Title>
                            <Card.Text>
                              <p>
                                <b>Venue:</b> {t.venue}
                              </p>
                              <p>
                                <b>Date:</b>{" "}
                                {t.startdate === t.enddate
                                  ? moment(t.startdate, "YYYY-MM-DD").format(
                                      "DD MMMM YYYY"
                                    )
                                  : moment(t.startdate, "YYYY-MM-DD").format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " - " +
                                    moment(t.enddate, "YYYY-MM-DD").format(
                                      "DD MMMM YYYY"
                                    )}
                              </p>
                              <p>
                                <b>Time:</b>{" "}
                                {moment(t.start_time, "HH:mm:ss").format(
                                  "hh:mm a"
                                ) +
                                  " - " +
                                  moment(t.end_time, "HH:mm:ss").format(
                                    "hh:mm a"
                                  )}
                              </p>
                            </Card.Text>
                            {getButton(t)}
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
            </div>
          </div>

          <div className="col-lg-1 col-sm-0 align-center left-column"></div>
          <div className="col-lg-5 col-sm-12">
            <h3 className="mb-40 text-center">
              <img
                className="venue-photo"
                src="assets/images/united-states.png"
              />
              USA
            </h3>
            <div className="event-list bg-light-yellow">
              {tournaments &&
                tournaments
                  .filter((t) => {
                    return t.championship_ind == 0 && t.country !== "Canada";
                  })
                  .map((t) => {
                    return (
                      <div className="row mt-10">
                        <Card className="event-card">
                          <Card.Body>
                            <Card.Title>{t.name}</Card.Title>
                            <Card.Text>
                              <p>
                                <b>Venue:</b> {t.venue}
                              </p>
                              <p>
                                <b>Date:</b>{" "}
                                {t.startdate === t.enddate
                                  ? moment(t.startdate, "YYYY-MM-DD").format(
                                      "DD MMMM YYYY"
                                    )
                                  : moment(t.startdate, "YYYY-MM-DD").format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " - " +
                                    moment(t.enddate, "YYYY-MM-DD").format(
                                      "DD MMMM YYYY"
                                    )}
                              </p>
                              <p>
                                <b>Time:</b>{" "}
                                {moment(t.start_time, "HH:mm:ss").format(
                                  "hh:mm a"
                                ) +
                                  " - " +
                                  moment(t.end_time, "HH:mm:ss").format(
                                    "hh:mm a"
                                  )}
                              </p>
                            </Card.Text>
                            {getButton(t)}
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Divisions2024;
