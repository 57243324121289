import React from "react";
// import { ReactComponent as Restaurant } from "../../../svg/restaurant.svg";
import { ReactComponent as Robot } from "../../../svg/robot.svg";
import { ReactComponent as Age } from "../../../svg/age.svg";
import { ReactComponent as Team } from "../../../svg/team.svg";
// import { ReactComponent as CreativeChallenge } from "../../../svg/creative_challenge.svg";

const ChallengeStrip = ({
  icon,
  category_name,
  age,
  team_size,
  third_title,
  third_text,
}) => {
  return (
    <section className="category-title">
      <div className="container">
        <div className="recipe-detail-box position-relative">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="recipe-quick-info">
                <div className="row">
                  <div className="col-md-1">
                    {icon}
                  </div>
                  <div className="col-md-11 my-auto">
                    <h3 className="fs-45 mb-2">{category_name}</h3>
                  </div>
                </div>
                <div className="recipe-prep-box bg-theme rounded-5 d-sm-flex justify-content-between flex-wrap position-relative">
                  <div className="prep-icon-box d-flex align-items-center">
                    <Age />
                    <div className="pl-2">
                      <span className="d-block fs-16 text-white">Age</span>
                      <strong className="text-white fs-20">{age} yrs</strong>
                    </div>
                  </div>
                  {team_size && (
                    <div className="prep-icon-box d-flex align-items-center">
                      <Team />
                      <div className="pl-2">
                        <span className="d-block fs-14 fs-16 text-white">
                          Team Size
                        </span>
                        <strong className="text-white fs-20">
                          {team_size}
                        </strong>
                      </div>
                    </div>
                  )}
                  {third_title && (
                  <div className="prep-icon-box d-flex align-items-center">
                    <Robot />
                    <div className="pl-2">
                      <span className="d-block fs-16 text-white">{third_title} </span>
                      <strong className="text-white fs-20">
                        {third_text}
                      </strong>
                    </div>
                  </div>
)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChallengeStrip;
