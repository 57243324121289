import React, { useEffect, useState } from "react";
import Header from "../header/Header2";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import MetaTags from "react-meta-tags";
import { Button, Card, Table } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const Winners = () => {
  return (
    <>
      <MetaTags>
        <title>S.T.R.I.P.E. Competition</title>
        <meta
          name="description"
          content="S.T.R.I.P.E. Competition is an open STEM competition encompassing
        science, technology, robotics, innovation, programming, and engineering. Our competition
        allows students to show case their skills in the field of STEM while
        applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title={`Winners`} />
      {/* About Us Sec */}
      <section className="sec-space">
        <div className="container">
          <div className="section pb-30">
            <h2>Ethics</h2>
            <Table
              className="scoreboard"
              striped
              bordered
              hover
              size="lg"
            >
              <thead>
                <tr>
                  <th>Ethic</th>
                  <th>Team Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Perseverance</td>
                  <td>Tanya</td>
                </tr>
                <tr>
                  <td>Collaboration</td>
                  <td>Tejashree - Devanshi</td>
                </tr>
                <tr>
                  <td>Respect</td>
                  <td>Aubrey - Nikhil - Aaradhya</td>
                </tr>
              </tbody>
            </Table>

          </div>
          <div className="section pb-30">
            <h2>Robotics</h2>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Nebula Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                          <th>Round 1</th>
                          <th>Round 2</th>
                          <th>Round 3</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 101</td>
                          <td>Subakarthik Aryaman</td>
                          <td>15</td>
                          <td>20</td>
                          <td>60</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 104</td>
                          <td>Patrick - Matteo - Aryan</td>
                          <td>35</td>
                          <td>45</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 107</td>
                          <td>Manish - Satyaki - Reyaansh</td>
                          <td>35</td>
                          <td>35</td>
                          <td>50</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Star Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                          <th>Round 1</th>
                          <th>Round 2</th>
                          <th>Round 3</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 204</td>
                          <td>Advik - Vishnu - Rajas</td>
                          <td>15</td>
                          <td>55</td>
                          <td>15</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 201</td>
                          <td>Shlok - Tej - Madheswaran</td>
                          <td>35</td>
                          <td>40</td>
                          <td>45</td>
                        </tr>
                        <tr>
                          <td>
                            3rd
                          </td>
                          <td>Team 208</td>
                          <td>Andrew</td>
                          <td>40</td>
                          <td>35</td>
                          <td>30</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          <div className="section pb-30">
            <h2>Innovation</h2>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Nebula Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 606</td>
                          <td>Mrittika - Veer</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 601</td>
                          <td>Jovan - Kishi - Sijing</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Star Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 701</td>
                          <td>Arnav - Rishy</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          <div className="section pb-30">
            <h2>Programming</h2>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Nebula Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 302</td>
                          <td>Ibraheem - Humaid - Mohammad</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Star Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 801</td>
                          <td>Bogdan - Tejas</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 802</td>
                          <td>Abdullah - Aarash</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Super Nova Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 402</td>
                          <td>Vedant - Nathan</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 406</td>
                          <td>Vidujith - Justin - George</td>
                        </tr>
                        <tr>
                          <td>
                            3rd
                          </td>
                          <td>Team 407</td>
                          <td>Mark - Vishwas</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card key={0}>
                <Card.Header>
                  <Accordion.Toggle
                    className="scoreboardLabel"
                    as={Button}
                    variant="link"
                    eventKey={1}
                  >
                    Super Nova Category
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <Table
                      className="scoreboard"
                      striped
                      bordered
                      hover
                      size="lg"
                    >
                      <thead>
                        <tr>
                          <th>Place</th>
                          <th>Team Number</th>
                          <th>Team Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1st
                          </td>
                          <td>Team 502</td>
                          <td>Hana</td>
                        </tr>
                        <tr>
                          <td>
                            2nd
                          </td>
                          <td>Team 503</td>
                          <td>Kiran</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Winners;
