import React, { Component, useDebugValue, useState, useEffect } from "react";
import { useHistory } from "react-router";
import Header from "../header/Header2";
import Footer from "../footer/Footer";
import "slick-carousel/slick/slick.css";
import Banner from "../header/Banner";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import StreamScoreboard from "./StreamScoreboard";
import ScoreboardFilters from "./ScoreboardFilters";

const ScoreboardList = () => {
  return (
    <>
      <MetaTags>
        <title>S.T.R.I.P.E. Competition</title>
        <meta
          name="description"
          content="S.T.R.I.P.E. Competition is an open STEM competition encompassing
        science, technology, robotics, innovation, programming, and engineering. Our competition
        allows students to show case their skills in the field of STEM while
        applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="Scoreboards" />
      {/* About Us Sec */}
      <section className="sec-space">
        <div className="container">
          <StreamScoreboard />
        </div>
      </section>
      <Footer />
    </>
  );
};
export default ScoreboardList;
