import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../../footer/Footer";
import Banner from "../../../header/Banner";
import Header from "../../../header/Header2";
import SeasonChallenges2024 from "../ChallengesList/2024SeasonChallenges";
import Divisions2024 from "./Divisions2024";

const S2024HomePage = () => {
  if (window.location.href.indexOf("#challenges") > 0) {
    const anchor = document.querySelector("#challenges");
    anchor?.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  return (
    <Fragment>
      <MetaTags>
        <title>2023-2024 Season</title>
        <meta
          name="description"
          content="Competition, In-Person Competition, STEM, Coding, Programming, Kids,
          Robotics, Java, Python, Canada, USA, Mississauga, After school program, 
          Robotics Club, Milton, Cary, Arduino, Technology,
          Burlington, Toronto, Danforth, Brampton, Raleigh, Mount Olive, Mt. Olive"
        />
        <link
          rel="canonical"
          href="https://www.stripecompetition.com/2023season"
        />
      </MetaTags>
      <Header />
      <Banner link="2024season" title="2023-2024 Season" />

      <Divisions2024 bgClass={""} />

      {/* <SeasonBreakdown2024 bgClass={"bg-light-blue "} /> */}

      <div
        className="sec-space bg-light-blue"
        id="challenges"
        name="challenges"
      >
        <SeasonChallenges2024 bgClass={""} />
      </div>

      {/* <Majors2024 bgClass={"bg-light-blue "} /> */}
      <Footer />
    </Fragment>
  );
};
export default S2024HomePage;
