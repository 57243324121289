import React, { Fragment } from "react";

const Organizers = () => {
  let organizers = [
    {
      company_name: "Zebra Robotics",
      company_description:
        "For over 8 years Zebra Robotics has been conducting coding and robotics classes for students grade 2 to grade 12." +
        " Their contribution to STEM education has been a huge inspiration to the S.T.R.I.P.E Competition team. Their robotics" +
        " and coding courses tech students the skills they need to develop the skills they need to pursue careers in STEM fields.",
      company_image: "/assets/images/zebra_logo.png",
      link: "https://zebrarobotics.com",
      width: "75%",
      height: "75%",
    },
  ];
  return (
    <>
      <section className="sec-space">
        <div className="container">
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45 mb-40">Organizer</h2>
          </div>
          {organizers.map((p, i) => {
            return (
              <div className="row" key={i}>
                <div className="col-md-5 text-center my-auto">
                  <img
                    src={p.company_image}
                    alt={p.company_name}
                    width={p.width}
                    height={p.height}
                  />
                </div>
                <div className="col-md-7">
                  <a href={p.link}>
                    <h2>{p.company_name}</h2>
                  </a>
                  <p>{p.company_description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

const PremiumSponsors = () => {
  let premiumSponsors = [
    {
      company_name: "Hatch",
      tier_color: "silverSponsor",
      tier: "Silver Sponsor",
      company_description:
        "Hatch is a global engineering, project management, and professional services firm." +
        "Whatever our clients envision, our professionals can design and build. With over six decades of business and technical experience in the energy," +
        " infrastructure, and mining sectors, we know your business and understand that your challenges are changing rapidly. We respond quickly with solutions" +
        " that are smarter, more efficient and innovative. We rely upon our 10,000 staff with experience in over 150 countries to challenge the status quo and create" +
        " positive change for our clients, our employees, and the communities we serve.  ",
      company_image: "/assets/images/Hatch_Logo_Colour_RGB.png",
      link: "https://www.hatch.com/",
      width: "75%",
      height: "75%",
    },
    {
      company_name: "Mississauga Hyundai",
      tier_color: "bannerSponsor",
      tier: "Banner Sponsor",
      company_description:
        "Mississauga Hyundai is the Home of the Toronto Raptors Superfan, Nav Bhatia! In business for over 30+ years, Mississauga Hyundai has been the top volume retailer for Hyundai in Canada and Ontario for the past decade. Our award winning staff is available to assist you online and in-person where our dealership is open 6 Days a week for Sales, Parts and Service. Looking for a Hyundai? Nav's your guy!",
      company_image: "/assets/images/mhs_logo_darkgrey.png",
      link: "https://Mississaugahyundai.com",
      width: "75%",
    },
  ];
  return (
    <>
      <section className="sec-space bg-light-blue">
        <div className="container">
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45 mb-40">Sponsors</h2>
          </div>
          {premiumSponsors.map((p, i) => {
            return (
              <div className="row pb-100" key={i}>
                <div className="col-md-5 text-center my-auto">
                  <img
                    src={p.company_image}
                    alt={p.company_name}
                    width={p.width}
                    height={p.height}
                  />
                </div>
                <div className="col-md-7">
                  <a href={p.link}>
                    <h2>{p.company_name}</h2>
                    <h4 className={p.tier_color}>{p.tier}</h4>
                  </a>
                  <p>{p.company_description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

const Supporters = () => {
  let supportersList = [
    {
      image: "https://www.shad.ca/wp-content/uploads/2019/05/SHAD-logo@2x.png",
      name: "SHAD Canada",
      width: 330,
      height: 160,
    },
    {
      image:
        "https://static.wixstatic.com/media/7001cb_eb6b131980a94be595e75a11adbcf0b4~mv2.png/v1/crop/x_0,y_114,w_500,h_264/fill/w_263,h_139,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/BioTroopers%20Logo%20Horizontal%20Transparent.png",
      name: "BioTroopers",
      width: 290,
      height: 160,
    },
    {
      image:
        "https://static.wixstatic.com/media/b2ca9c_0e700fca1887499fbb0474d9207deb7c~mv2.png/v1/crop/x_338,y_410,w_356,h_348/fill/w_164,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b2ca9c_0e700fca1887499fbb0474d9207deb7c~mv2.png",
      name: "Girls SySTEM Mentorship",
      width: 180,
      height: 180,
    },
    {
      image:
        "https://letstalkscience.ca/themes/custom/lts_barrio2/assets/logos/LTS-Logo-Light-en.svg",
      name: "Let's Talk Science",
      width: 280,
      height: 180,
    },
  ];

  return (
    <>
      <section className="sec-space">
        <div className="container">
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45 mb-40">Our Supporters</h2>
          </div>
          <div className="row">
            {supportersList.map((s, i) => {
              return (
                <div className={`col-md-${12 / supportersList.length}`} key={i}>
                  <img
                    src={s.image}
                    alt={s.name}
                    width={s.width}
                    height={s.height}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

const Sponsors2023 = () => {
  return (
    <>
      {/* ContactRegistration */}
      <Organizers />
      <PremiumSponsors />
      <Supporters />
    </>
  );
};

export default Sponsors2023;
