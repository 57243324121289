import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { API_ROOT } from "../../../config/env-var";

const LiveBoard = () => {
  let location = useLocation();
  let id = new URLSearchParams(location.search).get("event_id");
  const [scoreData, setScoreData] = useState([]);

  const fetchScores = async () => {
    let scores = await axios
      .get(`${API_ROOT}/scores/by-event/${id}`)
      .catch(console.log);
    console.log("raw", scores.data.results);
    setScoreData(scores.data.results);
  };
  useEffect(() => {
    fetchScores();
  }, []);

  return (
    <>
      <table className="live-board">
        <thead>
          <tr>
            <th className="text-muted fs-26-hard pl-30">Rank</th>
            <th className="text-muted fs-26-hard">Team Name</th>
            <th className="text-muted fs-26-hard">Round 1</th>
            <th className="text-muted fs-26-hard">Round 2</th>
            <th className="text-muted fs-26-hard">Round 3</th>
            <th className="text-muted fs-26-hard">Final</th>
          </tr>
        </thead>
        <tbody className="marquee">
          {/*
                Programming Nebula
            */}
          {scoreData && scoreData["Programming-1"]?.length > 0 ? (
            <>
              <tr>
                <td className="text-center" colSpan="2">
                  <h3>Programming - Nebula</h3>
                </td>
                <td className="text-center" colSpan="2">
                  <img
                    className="venue-photo"
                    src="assets/images/stripe_logo.png"
                  />
                </td>
                <td className="text-center" colSpan="2">
                  <h3>Programming - Nebula</h3>
                </td>
              </tr>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {scoreData &&
            scoreData["Programming-1"]?.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={index % 2 == 0 ? "bg-light-row" : "bg-dark-row"}
                >
                  <td>
                    <p>
                      <span className="fs-26-hard pl-30"> {index + 1}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className="fs-26-hard">{data.team_id}.</span>
                      <span className="fs-26-hard pl-30">{data.team_name}</span>
                      <br />
                      <small className="text-muted">
                        Programming - {data.age_categories_name}
                      </small>
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {data.total_score ? data.total_score : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*
                Programming Star
            */}
          {scoreData && scoreData["Programming-2"]?.length > 0 ? (
            <>
              <tr>
                <td className="text-center" colSpan="2">
                  <h3>Programming - Star</h3>
                </td>
                <td className="text-center" colSpan="2">
                  <img
                    className="venue-photo"
                    src="assets/images/stripe_logo.png"
                  />
                </td>
                <td className="text-center" colSpan="2">
                  <h3>Programming - Star</h3>
                </td>
              </tr>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {scoreData &&
            scoreData["Programming-2"]?.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={index % 2 == 0 ? "bg-light-row" : "bg-dark-row"}
                >
                  <td>
                    <p>
                      <span className="fs-26-hard pl-30"> {index + 1}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className="fs-26-hard">{data.team_id}.</span>
                      <span className="fs-26-hard pl-30">{data.team_name}</span>
                      <br />
                      <small className="text-muted">
                        Programming - {data.age_categories_name}
                      </small>
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {data.total_score ? data.total_score : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*
                Programming Super Nova
            */}
          {scoreData && scoreData["Programming-3"]?.length > 0 ? (
            <>
              <tr>
                <td className="text-center" colSpan="2">
                  <h3>Programming - Super Nova</h3>
                </td>
                <td className="text-center" colSpan="2">
                  <img
                    className="venue-photo"
                    src="assets/images/stripe_logo.png"
                  />
                </td>
                <td className="text-center" colSpan="2">
                  <h3>Programming - Super Nova</h3>
                </td>
              </tr>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {scoreData &&
            scoreData["Programming-3"]?.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={index % 2 == 0 ? "bg-light-row" : "bg-dark-row"}
                >
                  <td>
                    <p>
                      <span className="fs-26-hard pl-30"> {index + 1}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className="fs-26-hard">{data.team_id}.</span>
                      <span className="fs-26-hard pl-30">{data.team_name}</span>
                      <br />
                      <small className="text-muted">
                        Programming - {data.age_categories_name}
                      </small>
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">N/A</p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {data.total_score ? data.total_score : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*
                Robotics Nebula
            */}
          {scoreData && scoreData["Robotics-1"]?.length > 0 ? (
            <>
              <tr>
                <td className="text-center" colSpan="2">
                  <h3>Robotics - Nebula</h3>
                </td>
                <td className="text-center" colSpan="2">
                  <img
                    className="venue-photo"
                    src="assets/images/stripe_logo.png"
                  />
                </td>
                <td className="text-center" colSpan="2">
                  <h3>Robotics - Nebula</h3>
                </td>
              </tr>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {scoreData &&
            scoreData["Robotics-1"]?.map((data, index) => {
              let roundOne = data.roundScores.filter((rs) => {
                return rs.round == 1;
              });
              let roundTwo = data.roundScores.filter((rs) => {
                return rs.round == 2;
              });
              let roundThree = data.roundScores.filter((rs) => {
                return rs.round == 3;
              });
              return (
                <tr
                  key={index}
                  className={index % 2 == 0 ? "bg-light-row" : "bg-dark-row"}
                >
                  <td>
                    <p>
                      <span className="fs-26-hard pl-30"> {index + 1}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className="fs-26-hard">{data.team_id}.</span>
                      <span className="fs-26-hard pl-30">{data.team_name}</span>
                      <br />
                      <small className="text-muted">
                        Robotics - {data.age_categories_name}
                      </small>
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundOne && roundOne.length > 0
                        ? roundOne[0].score
                        : "N/A"}
                      {roundOne && roundOne.length > 0 && roundOne[0].time
                        ? `(${roundOne[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundTwo && roundTwo.length > 0
                        ? roundTwo[0].score
                        : "N/A"}
                      {roundTwo && roundTwo.length > 0 && roundTwo[0].time
                        ? `(${roundTwo[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundThree && roundThree.length > 0
                        ? roundThree[0].score
                        : "N/A"}
                      {roundThree && roundThree.length > 0 && roundThree[0].time
                        ? `(${roundThree[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {data.base_inc_score ? data.base_inc_score : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          {/*
                Robotics Star
            */}
          {scoreData && scoreData["Robotics-2"]?.length > 0 ? (
            <>
              <tr>
                <td className="text-center" colSpan="2">
                  <h3>Robotics - Star</h3>
                </td>
                <td className="text-center" colSpan="2">
                  <img
                    className="venue-photo"
                    src="assets/images/stripe_logo.png"
                  />
                </td>
                <td className="text-center" colSpan="2">
                  <h3>Robotics - Star</h3>
                </td>
              </tr>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {scoreData &&
            scoreData["Robotics-2"]?.map((data, index) => {
              let roundOne = data.roundScores.filter((rs) => {
                return rs.round == 1;
              });
              let roundTwo = data.roundScores.filter((rs) => {
                return rs.round == 2;
              });
              let roundThree = data.roundScores.filter((rs) => {
                return rs.round == 3;
              });
              return (
                <tr
                  key={index}
                  className={index % 2 == 0 ? "bg-light-row" : "bg-dark-row"}
                >
                  <td>
                    <p>
                      <span className="fs-26-hard pl-30"> {index + 1}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className="fs-26-hard">{data.team_id}.</span>
                      <span className="fs-26-hard pl-30">{data.team_name}</span>
                      <br />
                      <small className="text-muted">
                        Robotics - {data.age_categories_name}
                      </small>
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundOne && roundOne.length > 0
                        ? roundOne[0].score
                        : "N/A"}
                      {roundOne && roundOne.length > 0 && roundOne[0].time
                        ? `(${roundOne[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundTwo && roundTwo.length > 0
                        ? roundTwo[0].score
                        : "N/A"}
                      {roundTwo && roundTwo.length > 0 && roundTwo[0].time
                        ? `(${roundTwo[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundThree && roundThree.length > 0
                        ? roundThree[0].score
                        : "N/A"}
                      {roundThree && roundThree.length > 0 && roundThree[0].time
                        ? `(${roundThree[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {data.avg_score ? data.avg_score : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*
                Robotics Super Nova
            */}
          {scoreData && scoreData["Robotics-3"]?.length > 0 ? (
            <>
              <tr>
                <td className="text-center" colSpan="2">
                  <h3>Robotics - Super Nova</h3>
                </td>
                <td className="text-center" colSpan="2">
                  <img
                    className="venue-photo"
                    src="assets/images/stripe_logo.png"
                  />
                </td>
                <td className="text-center" colSpan="2">
                  <h3>Robotics - Super Nova</h3>
                </td>
              </tr>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {scoreData &&
            scoreData["Robotics-3"]?.map((data, index) => {
              let roundOne = data.roundScores.filter((rs) => {
                return rs.round == 1;
              });
              let roundTwo = data.roundScores.filter((rs) => {
                return rs.round == 2;
              });
              let roundThree = data.roundScores.filter((rs) => {
                return rs.round == 3;
              });
              return (
                <tr
                  key={index}
                  className={index % 2 == 0 ? "bg-light-row" : "bg-dark-row"}
                >
                  <td>
                    <p>
                      <span className="fs-26-hard pl-30"> {index + 1}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className="fs-26-hard">{data.team_id}.</span>
                      <span className="fs-26-hard pl-30">{data.team_name}</span>
                      <br />
                      <small className="text-muted">
                        Robotics - {data.age_categories_name}
                      </small>
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundOne && roundOne.length > 0
                        ? roundOne[0].score
                        : "N/A"}
                      {roundOne && roundOne.length > 0 && roundOne[0].time
                        ? `(${roundOne[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundTwo && roundTwo.length > 0
                        ? roundTwo[0].score
                        : "N/A"}
                      {roundTwo && roundTwo.length > 0 && roundTwo[0].time
                        ? `(${roundTwo[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {roundThree && roundThree.length > 0
                        ? roundThree[0].score
                        : "N/A"}
                      {roundThree && roundThree.length > 0 && roundThree[0].time
                        ? `(${roundThree[0].time})`
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p className="fs-26-hard">
                      {data.avg_score ? data.avg_score : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};
export default LiveBoard;
