import React, { useState, useCallback, useEffect } from "react";
import Header from "../header/Header2";
import Footer from "../footer/Footer";
import "slick-carousel/slick/slick.css";
import Banner from "../header/Banner";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import axios from "axios";
import { API_ROOT } from "../../config/env-var";


const GalleryPage = () => {
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const fetchPhotos = async () => {
    let tempPhotos = await axios
      .get(`${API_ROOT}/gallery`)
      .catch((err) => {
        console.log("Error: ", err);
      });
    console.log("got", tempPhotos.data.results)
    setPhotos(tempPhotos.data.results);
  };

  useEffect(() => {
    fetchPhotos();
  }, [])

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <MetaTags>
        <title>S.T.R.I.P.E. Competition</title>
        <meta
          name="description"
          content="S.T.R.I.P.E. Competition is an open STEM competition encompassing
        science, technology, robotics, innovation, programming, and engineering. Our competition
        allows students to show case their skills in the field of STEM while
        applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="Gallery" />
      {/* About Us Sec */}
      <section className="sec-space">
        <div className="container">
          <div className="row">
            {photos && <Gallery photos={photos} onClick={openLightbox} />}
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default GalleryPage;
