import React, { Fragment } from "react";
import Header from "../header/Header2";
import Footer from "../footer/Footer";
import HomeTwoSlider from "../slider/HomeTwoSlider";
import Streams from "../Common/Streams";
// import Venue from "../Common/Venue";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import SeasonBreakdown from "../Common/SeasonBreakdown"
import { useRef } from "react";

const Homepage = () => {
  const myInfoSection = useRef(null)
  return (
    <Fragment>
      <MetaTags>
        <title>S.T.R.I.P.E. Competition</title>
        <meta
          name="description"
          content="Competition, In-Person Competition, STEM, Coding, Programming, Kids,
          Robotics, Java, Python, Canada, USA, Mississauga, After school program, 
          Robotics Club, Milton, Cary, Arduino, Technology,
          Burlington, Toronto, Danforth, Brampton, Raleigh, Mount Olive, Mt. Olive"
        />
        <link rel="canonical" href="https://www.stripecompetition.com/" />
      </MetaTags>
      <Header />
      <section className="main-slider2 position-relative">
        <HomeTwoSlider />
        <div className="container">
          <div className="kenburn-text text-center kenburn-background">
            <h3 className="position-relative text-theme">Earn Your Stripes</h3>
            <h2 className="text-theme text-white">S.T.R.I.P.E. Competition</h2>
            
            <button className="theme-btn-secondary" onClick={() => {
              myInfoSection.current.scrollIntoView()
            }}>Learn More</button>
          </div>
        </div>
      </section>
      {/* Countdown */}
      {/* <section>
        <Countdown />
      </section> */}
      {/* Season Breakdown */}
      <section>
        <SeasonBreakdown myInfoSection={myInfoSection}/>
      </section>
      {/* Venue Info */}
      {/* <section>
        <Venue />
      </section> */}
      {/* Category */}
      <section>
        <Streams />
      </section>
      {/* <VideoSection /> */}
      <Footer />
    </Fragment>
  );
};
export default Homepage;
