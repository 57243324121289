import axios from "axios";
import { Field, Form, Formik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { API_ROOT } from "../../../../config/env-var";
const initValues = {
  event: 9,
  round: 1,
  team_id: -1,
  score: 0,
  time: "",
  team: undefined,
  created_on: moment(new Date()).format("yyyy-MM-DD hh:mm:ss"),
  show_ind: true,
};

const schema = Yup.object().shape({
  event: Yup.number().required("Select an Event"),
  round: Yup.number().required("Select a Round Number"),
  team_id: Yup.number().required("Select a Team"),
  score: Yup.number().required("Enter a Score"),
  time: Yup.string().matches(
    /[0-9]{2}:[0-9]{2}/,
    "Please enter time in MM:SS format"
  ),
  show_ind: Yup.bool(),
});

const getTeamOptions = (teams) => {
  let labels = [
    "Programming - Nebula",
    "Programming - Star",
    "Programming - Super Nova",
    "Robotics - Nebula",
    "Robotics - Star",
    "Robotics - Super Nova",
    "Innovation - Nebula",
    "Innovation - Star",
    "Innovation - Super Nova",
  ];
  let teamsObj = {
    "Programming - Nebula": [],
    "Programming - Star": [],
    "Programming - Super Nova": [],
    "Robotics - Nebula": [],
    "Robotics - Star": [],
    "Robotics - Super Nova": [],
    "Innovation - Nebula": [],
    "Innovation - Star": [],
    "Innovation - Super Nova": [],
  };
  let streams = ["Programming", "Robotics", "Innovation"];
  teams.forEach((element) => {
    let label =
      streams[element.stream_id - 1] + " - " + element.age_categories_name;
    teamsObj[label] = [...teamsObj[label], element];
  });

  return labels.map((l) => {
    return (
      <optgroup label={l} key={l}>
        {teamsObj[l]
          .sort((a, b) => a.team_id - b.team_id)
          .map((a) => {
            return (
              <option key={a.team_id} value={a.team_id}>
                {a.team_id} - {a.team_name}
              </option>
            );
          })}
      </optgroup>
    );
  });
};

export function AddScore({ showAdd, toggleAddModal }) {
  const [tournaments, setTournaments] = useState([]);
  const [teams, setTeams] = useState([]);

  const fetchTournaments = async () => {
    let tournaments = await axios
      .get(`${API_ROOT}/tournaments`)
      .catch(console.log);
    setTournaments(tournaments.data);
  };

  const fetchTeams = async (event) => {
    let teams = await axios
      .get(`${API_ROOT}/scores/get-teams-by-event/${event}`)
      .catch(console.log);
    setTeams(teams.data.results);
  };

  useEffect(() => {
    fetchTournaments();
    fetchTeams(9);
  }, []);

  const onHideModal = () => {
    setTeams([]);
    toggleAddModal();
  };

  async function handleSubmit(values) {
    try {
      const res = await axios.post(`${API_ROOT}/scores/`, values);
      console.log(res);
      onHideModal();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal
      show={showAdd}
      onHide={onHideModal}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">Add Score</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Formik
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, errors, touched, values }) => (
              console.log(values, errors),
              (
                <Form className="form">
                  <div className="form-group row mb-18">
                    <div className="col-md-3">
                      <label>Event #</label>
                    </div>

                    <div className="col-md-6">
                      <select
                        className={
                          "form-control" +
                          (errors.event && touched.event ? " is-invalid" : "")
                        }
                        onChange={(e) => {
                          setFieldValue("event", e.target.value);
                          fetchTeams(e.target.value);
                        }}
                        value={values.event}
                      >
                        <option key={""} value={-1}>
                          Select an Event
                        </option>
                        {tournaments
                          .sort((a, b) => {
                            return a.name < b.name
                              ? -1
                              : a.name == b.name
                              ? 0
                              : 1;
                          })
                          .map((a) => (
                            <option key={a.id} value={a.id}>
                              {a.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {values.event !== -1 && teams.length > 0 && (
                    <div className="form-group row mb-18">
                      <div className="col-md-3">
                        <label>Team</label>
                      </div>
                      <div className="col-md-6">
                        <select
                          className={
                            "form-control" +
                            (errors.team_id && touched.team_id
                              ? " is-invalid"
                              : "")
                          }
                          onChange={(e) => {
                            setFieldValue("team_id", e.target.value);
                            let selectedTeam = teams.filter((t) => {
                              return t.team_id == e.target.value;
                            })[0];
                            setFieldValue("team", selectedTeam);
                          }}
                        >
                          <option key={""} value={-1}>
                            Select a team
                          </option>

                          {teams && getTeamOptions(teams)}
                        </select>
                      </div>
                    </div>
                  )}
                  {values.event !== -1 &&
                    typeof values.team !== "undefined" && (
                      <>
                        <div className="form-group row mb-18">
                          <div className="col-md-3">
                            <label>Round #</label>
                          </div>

                          <div className="col-md-6">
                            <Field name="round">
                              {({ field }) => (
                                <select
                                  {...field}
                                  className={
                                    "form-control" +
                                    (errors.round && touched.round
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="round"
                                >
                                  {[...Array(21).keys()]
                                    .filter((x) => {
                                      return x != 0;
                                    })
                                    .map((a) => (
                                      <option key={a} value={a}>
                                        {a}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form-group row  mb-18">
                          <div className="col-lg-3">
                            <label className={"required-field"}>
                              Enter Score
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <Field
                              className={
                                "form-control" +
                                (errors.score && touched.score
                                  ? " is-invalid"
                                  : "")
                              }
                              label="First Name"
                              name={`score`}
                              type="number"
                            />
                          </div>
                        </div>
                        {typeof values.team != "undefined" &&
                          values.team.stream_id === 2 && (
                            <div className="form-group row  mb-18">
                              <div className="col-lg-3">
                                <label className={"required-field"}>
                                  Enter Time
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <Field
                                  className={
                                    "form-control" +
                                    (errors.time && touched.time
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="00:00"
                                  label="First Name"
                                  name={`time`}
                                  type="text"
                                />
                              </div>
                            </div>
                          )}
                        <div className="form-group row">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-6">
                            <span className="text-danger">
                              {errors.time && touched.time ? errors.time : ""}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row align-center justify-content-center">
                          <div className="col-lg-2">
                            <label className={"required-field"}>Show</label>
                          </div>
                          <div className="col-lg-4">
                            <Field
                              name={`show_ind`}
                              type="checkbox"
                              checked={values.show_ind}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Button
                        type="button"
                        onClick={onHideModal}
                        className="btn btn-light btn-elevate"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6"></div>
                    <div className="col-lg-2">
                      <Button
                        type="submit"
                        className="btn btn-primary btn-elevate"
                        disabled={
                          Object.keys(errors).length > 0 || values.team_id == -1
                        }
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </Form>
              )
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
}
