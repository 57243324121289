import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';



function printLogo() {
console.log("  __    __    __   ")
console.log("  \\ \\   \\ \\   \\ \\  ")
console.log("   \\ \\   \\ \\   \\ \\ ")
console.log("   / /   / /   / / ")
console.log("  /_/   /_/   /_/  ")
console.log("                   ")
console.log("")
console.log("__________________________________________________________")
console.log("/   _____/\\__    ___/\\______   \\ |   |\\______   \\_   _____/")
console.log("\\_____  \\   |    |    |       _/ |   ||     ___/|    __)_ ")
console.log("/        \\  |    |    |    |   \\ |   ||    |    |        \\")
console.log("/_______  /  |____|    |____|_  /|___||____|   /_______  /")
console.log("Bike lock code -> 3182")
}

printLogo()
console.log("Hey, do me a favor,if you see any bugs or errors can you just email me at info@stripecomeptition.com.\n\t-Thanks Asad");

ReactDOM.render(
      <App />,
  document.getElementById("root")
);
