import React, { Component } from "react";
import { Link } from "react-router-dom";
import { tournaments } from "../../data/tickets";

const TicketFilters = ({ originalData, setData, streams, setStreams }) => {
  const filterData = (c) => {
    let filteredData = originalData.filter((od) => {
      if (c.name == od.stream_name) {
        return c.included == 0;
      } else {
        return (
          streams.map((s) => {
            return s.name == od.stream_name && s.included == 1;
          }).length > 0
        );
      }
    });

    setData(filteredData);
  };

  return (
    <aside>
      <div className="ticket-widget bg-light-blue">
        <h4 className="mb-20">Streams</h4>
        {streams.map((c, index) => {
          console.log(c);
          return (
            <>
              <button
                key={index}
                className={`mb-20 ${
                  c.included ? "theme-btn-secondary" : "theme-btn-muted"
                }`}
                onClick={(e) => {
                  let newStreams = streams.filter((s) => {
                    return s.name != c.name;
                  });
                  newStreams.push({
                    name: c.name,
                    included: c.included == 1 ? 0 : 1,
                  });
                  newStreams.sort((a, b) => {
                    return a.name.charCodeAt(0) - b.name.charCodeAt(0);
                  });
                  setStreams(newStreams);
                  filterData(c);
                }}
              >
                {c.name}
              </button>
            </>
          );
        })}
      </div>
    </aside>
  );
};
export default TicketFilters;
