import React from "react";

const Banner = ({ link, title, hideBreadcrumbs }) => {
  return (
    <section className="main-slider2 position-relative top-banner">
      <div
        className="bg-fixed"
        style={{
          backgroundImage: "url(assets/images/STRIPE-pattern2.png)",
        }}
      ></div>
      <div className="top-banner-content text-center">
        <div className="baner-name">
          <h2 className="fs-50 text-uppercase">{title}</h2>
          {!hideBreadcrumbs && (
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center p-0 mb-0">
                <li className="breadcrumb-item text-gray fs-18 fm-arimo">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active text-secondary fs-18 fm-arimo">
                  <a href={`/${link ? link : title}`}>{title}</a>
                </li>
              </ol>
            </nav>
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
