import React from "react";
import { AuthContextProvider } from "./context/auth-context";
import StripeRoutes from "./StripeRoutes";

const App = () => {
  return (
    <AuthContextProvider>
      <StripeRoutes />
    </AuthContextProvider>
  );
};

export default App;
