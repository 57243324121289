import React from "react";
import Accordion from "react-bootstrap/Accordion";
import uuid from "uuid";
import policy from "../data/policies.json";
import { Button, Card } from "react-bootstrap";
import Header from "../component/header/Header2";
import Footer from "../component/footer/Footer";
import Banner from "../component/header/Banner";

const AccordionItem = ({ information }) => {
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={information.id}
          className="policyTitle"
        >
          {information.title
            .split(" ")
            .map((str) => {
              return str.charAt(0).toUpperCase() + str.slice(1);
            })
            .join(" ")}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={information.id}>
        <Card.Body>
          <div dangerouslySetInnerHTML={{ __html: information.info }}></div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const TermsAndCopyright = ({ location }) => {
  return (
    <>
      <Header />
      {/* <!-- Breadcrumbs --> */}
      <Banner title="Policies" />
      <main className="pt-60 pb-30 mt-40 mb-40">
        <section>
          <div className="container">
            <Accordion>
              {policy.length > 0 &&
                policy.map((question) => (
                  <AccordionItem information={question} key={uuid()} />
                ))}
            </Accordion>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default TermsAndCopyright;
