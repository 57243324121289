import React from "react";
import { ReactComponent as Programming } from "../../svg/programming.svg";
// import { ReactComponent as Technology } from "../../svg/technology.svg";
import { ReactComponent as Arrow } from "../../svg/right-arrow.svg";
import { ReactComponent as Robotics } from "../../svg/robotics.svg";
// import { ReactComponent as BoTM } from "../../svg/battle_of_the_minds.svg";

const ServiceCatIcon1 = <Programming />;
// const ServiceCatIcon2 = <Technology />;
// const ServiceCatIcon3 = <BoTM />;
const ServiceCatIcon4 = <Robotics />;

const ServiceCat = [
  {
    link: "/Robotics",
    class: "robotics",
    category: "Robotics",
    icon: ServiceCatIcon4,
  },
  // {
  //   link: "/Innovation",
  //   class: "innovation",
  //   category: "Innovation",
  //   icon: ServiceCatIcon2,
  // },
  {
    link: "/Programming",
    class: "programming",
    category: "Programming",
    icon: ServiceCatIcon1,
  },
  /*
  {
    link: "/Botm",
    class: "botm",
    category: "BoT-M",
    icon: ServiceCatIcon3,
  },*/
];

const Streams = () => {
  return (
    <div className="sec-space">
      <div className="container">
        <div className="row">
          <div className="sec-heading text-center">
            <h2 className="fs-45 mb-40">Streams</h2>
          </div>
        </div>
        <div className="row">
          {ServiceCat.map((value, index) => (
            <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
              <div
                className={`food-cat-box ${value.class} text-center position-relative`}
              >
                <a href={`${value.link}`} title="">
                  <div
                    className={`${value.class + "-hover"} position-absolute`}
                  ></div>
                  <div className="food-cat-inner">
                    {value.icon}
                    <h4 className="text-capitalize fs-26 mb-0 mt-1">
                      {value.category}
                    </h4>
                  </div>
                </a>
                <a
                  href={`${value.link}`}
                  className="go-detail d-inline-block rounded-circle"
                >
                  <Arrow />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Streams;
