import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { API_ROOT } from "../../../config/env-var";

const prepareFilter = (values) => {
  const { idFilter, roundFilter, streamFilter, ageFilter, eventFilter } =
    values;
  const filter = {};

  filter.id = idFilter !== "" ? idFilter : "";
  filter.round = roundFilter !== "" ? roundFilter : "";
  filter.streams = streamFilter !== "" ? streamFilter : "";
  filter.age_categories = ageFilter !== "" ? ageFilter : "";
  filter.event = eventFilter !== "" ? eventFilter : "";

  return filter;
};

const ScoreFilters = ({ origData, scoreRowData, setScoreRowData }) => {
  const formRef = useRef();
  const [tournaments, setTournaments] = useState([]);

  const initFilters = {
    idFilter: "",
    roundFilter: "",
    streamFilter: "",
    ageFilter: "",
    eventFilter: "",
  };

  const formik = useFormik({
    initialValues: initFilters,
    onSubmit: (values) => {
      applyFilter(values);
    },
  });
  const { values, handleSubmit, handleBlur, handleChange, setFieldValue } =
    formik;

  const fetchTournaments = async () => {
    let tournaments = await axios
      .get(`${API_ROOT}/tournaments`)
      .catch(console.log);
    setTournaments(tournaments.data);
  };

  const filterData = (filters) => {
    const newData = origData.filter(function (item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "id") {
            if (
              item["team_id"] != filters[key] &&
              !item.team_name.includes(filters[key])
            ) {
              add = false;
            }
          }
          if (key === "round") {
            if (item["round_number"] != filters[key]) {
              add = false;
            }
          }
          if (key === "streams") {
            if (item["stream_id"] != filters[key]) {
              add = false;
            }
          }
          if (key === "age_categories") {
            if (item["age_categories_id"] + "" != filters[key]) {
              add = false;
            }
          }
          if (key === "event") {
            if (item["event_id"] != filters[key]) {
              add = false;
            }
          }
        }
      }
      return add && true;
    });

    setScoreRowData(newData);
  };

  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  };

  useEffect(() => {
    fetchTournaments();
  }, []);

  useEffect(() => {
    setFieldValue("idFilter", "");
    setFieldValue("roundFilter", "");
    setFieldValue("streamFilter", "");
    setFieldValue("ageFilter", "");
    setFieldValue("eventFilter", "");
  }, [origData, tournaments]);

  return (
    <>
      <div className="form-grp">
        <div className="">
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="ID"
                  name="idFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("idFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.idFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by ID
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Event"
                  name="eventFilter"
                  onChange={(e) => {
                    setFieldValue("eventFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.eventFilter}
                >
                  <option value={""}>All</option>
                  {tournaments
                    .sort((a, b) => {
                      return a.name < b.name ? -1 : a.name == b.name ? 0 : 1;
                    })
                    .map((x) => {
                      return (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      );
                    })}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Event
                </small>
              </div>

              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Round #"
                  name="roundFilter"
                  type="number"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("roundFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.roundFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by Round
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Stream"
                  name="streamFilter"
                  onChange={(e) => {
                    setFieldValue("streamFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.streamFilter}
                >
                  <option value={""}>All</option>
                  <option value={2}>Robotics</option>
                  <option value={1}>Programming</option>
                  <option value={3}>Innovation</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Stream
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Age Category"
                  name="ageFilter"
                  onChange={(e) => {
                    setFieldValue("ageFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.ageFilter}
                >
                  <option value={""}>All</option>
                  <option value={1}>Nebula</option>
                  <option value={2}>Star</option>
                  <option value={3}>Super Nova</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Age Category
                </small>
              </div>
              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ScoreFilters;
