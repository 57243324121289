import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { API_ROOT } from "../../config/env-var";
import ScoreboardFilters from "./ScoreboardFilters";

const Robotics_Scoreboard = ({ scores, category }) => {
  return scores && scores[`Robotics-${category}`] ? (
    <Table striped="columns">
      <thead>
        <tr>
          <td>Rank</td>
          <td>Team</td>
          <td>Round 1</td>
          <td>Round 2</td>
          <td>Round 3</td>
          <td>Final</td>
        </tr>
      </thead>
      <tbody>
        {scores[`Robotics-${category}`].map((t, rank) => {
          let roundOne = t.roundScores.filter((rs) => {
            return rs.round == 1;
          });
          let roundTwo = t.roundScores.filter((rs) => {
            return rs.round == 2;
          });
          let roundThree = t.roundScores.filter((rs) => {
            return rs.round == 3;
          });
          return (
            <tr>
              <td>{rank + 1}</td>
              <td>
                {t.team_id}. {t.team_name}
              </td>
              <td>
                {roundOne && roundOne.length > 0 ? roundOne[0].score : "N/A"}
                {roundOne && roundOne.length > 0 && roundOne[0].time
                  ? `(${roundOne[0].time})`
                  : ""}
              </td>

              <td>
                {roundTwo && roundTwo.length > 0 ? roundTwo[0].score : "N/A"}
                {roundTwo && roundTwo.length > 0 && roundTwo[0].time
                  ? `(${roundTwo[0].time})`
                  : ""}
              </td>

              <td>
                {roundThree && roundThree.length > 0
                  ? roundThree[0].score
                  : "N/A"}
                {roundThree && roundThree.length > 0 && roundThree[0].time
                  ? `(${roundThree[0].time})`
                  : ""}
              </td>

              <td>{category == 1 ? t.base_inc_score : t.avg_score}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  ) : (
    <p> Scores Not Yet Available</p>
  );
};

const Programming_Scoreboard = ({ scores, category }) => {
  return scores && scores[`Programming-${category}`] ? (
    <Table striped="columns">
      <thead>
        <tr>
          <td>Rank</td>
          <td>Team</td>
          <td>Score</td>
        </tr>
      </thead>
      <tbody>
        {scores[`Programming-${category}`].map((t, rank) => {
          return (
            <tr>
              <td>{rank + 1}</td>
              <td>
                {t.team_id}. {t.team_name}
              </td>
              <td>{t.total_score}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  ) : (
    <p> Scores Not Yet Available</p>
  );
};

const StreamScoreboard = () => {
  const [scores, setScores] = useState();
  const [eventIndex, setEventIndex] = useState(-1);
  const [eventList, setEventList] = useState([]);

  const fetchEvents = async () => {
    let eventsTemp = await axios.get(`${API_ROOT}/tournaments`).catch((err) => {
      console.log("Error: ", err);
    });
    let list = eventsTemp.data.map((e) => {
      return { name: e.name, id: e.id };
    });
    setEventList(list);
    setEventIndex(-1);
  };

  const fetchScores = async () => {
    let tempScores = await axios
      .get(`${API_ROOT}/scores/by-event/${eventIndex}`)
      .catch((err) => {
        console.log("Error: ", err);
      });
    setScores(tempScores.data.results);
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  useEffect(() => {
    eventIndex > 0 && fetchScores();
  }, [eventIndex]);

  return (
    <>
      <div className="row">
        <div className="col-10">
          {eventList.length > 0 && (
            <ScoreboardFilters
              eventList={eventList}
              eventIndex={eventIndex}
              setEventIndex={setEventIndex}
            />
          )}
        </div>

        <div className="col-2 refreshButton">
          <Button
            onClick={() => {
              fetchScores();
            }}
          >
            <i className="fa fa-refresh"></i>
          </Button>
        </div>
      </div>
      <div className="row mb-80">
        <h2>Robotics</h2>
        <h3>Nebula</h3>
        <Robotics_Scoreboard scores={scores} category={1} />
      </div>
      <div className="row mb-80">
        <h3>Star</h3>
        <Robotics_Scoreboard scores={scores} category={2} />
      </div>
      <div className="row mb-80">
        <h3>Super Nova</h3>
        <Robotics_Scoreboard scores={scores} category={3} />
      </div>
      <div className="row mb-80">
        <h2>Programming</h2>
        <h3>Nebula</h3>
        <Programming_Scoreboard scores={scores} category={1} />
      </div>
      <div className="row mb-80">
        <h3>Star</h3>
        <Programming_Scoreboard scores={scores} category={2} />
      </div>
      <div className="row mb-80">
        <h3>Super Nova</h3>
        <Programming_Scoreboard scores={scores} category={3} />
      </div>
    </>
  );
};
export default StreamScoreboard;
