import React, { useState } from "react";
import HTMLPdf from "../../../../data/RTP_STRIPE_HTML_CSS_JAVASCRIPT_Summer24.pdf";
import ScratchPdf from "../../../../data/RTP_Scratch_Summer24.pdf";
import PythonPdf from "../../../../data/RTP_PYTHON_SUMMER 24.pdf";
import NebulaPdf from "../../../../data/RTP_STRIPE_WeDo_V4.pdf";
import roboticsPdf from "../../../../data/STRIPE_SPIKE.pdf";

const challengeList = {
  robotics: {
    nebula: { type: "PDF", data: NebulaPdf },
    star: { type: "PDF", data: roboticsPdf },
  },
  programming: {
    nebula_scratch: { type: "PDF", data: ScratchPdf },
    star_html: { type: "PDF", data: HTMLPdf },
    star_js: { type: "PDF", data: HTMLPdf },
    super_nova: { type: "PDF", data: PythonPdf },
  },
};

const SeasonChallenges2024 = ({ bgClass }) => {
  const [selected, setSelected] = useState("robotics");
  return (
    <div className={`${bgClass} container`}>
      <div className="row">
        <div className="sec-heading text-center">
          <h2 className="fs-45 mb-40"> Season Challenges </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div className="row">
            {Object.keys(challengeList).map((category, i) => {
              return (
                <button
                  className={`${category}-btn mt-10`}
                  onClick={() => {
                    setSelected(category);
                  }}
                >
                  {category}
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-9">
          <div className="challenge-card">
            {challengeList &&
              selected &&
              Object.keys(challengeList[selected]).map((challenge) => {
                return (
                  <div className="row mt-10">
                    {" "}
                    <a
                      className="text-theme"
                      target="_blank"
                      href={challengeList[selected][challenge].data}
                    >
                      <h3>
                        Check out the 2023 - 2024{" "}
                        {challenge.split("_").join(" ").toUpperCase()}{" "}
                        challenges
                      </h3>
                      {challengeList[selected][challenge].type == "text" ? (
                        challengeList[selected][challenge].data == "" ? (
                          <p>To Be Announced Day of The Competition</p>
                        ) : (
                          <p>To Be Announced</p>
                        )
                      ) : (
                        <button className={`${selected}-sm-btn`}>
                          Challenge Details
                        </button>
                      )}
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SeasonChallenges2024;
