import React, { Component, useState, useEffect } from "react";

const Venue2023 = ({}) => {

  return (
    <div className="sec-space bg-light-blue">
      <div className="container">
        <div className="row">
          <div className="sec-heading text-center">
            <h2 className="fs-45 mb-40">We Rocked It At</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 text-center my-auto">
            <h4 className="venue-text">Venue:</h4>

            <h2>University of Toronto - Mississauga Campus</h2>
            <h4 className="venue-text">Date:</h4>
            <h2>June 18th 2023</h2>
            <h4 className="venue-text">Time:</h4>
            <h2>8:00AM - 6:00PM</h2>
            <a className="theme-btn-secondary" href={"/2023winners"} title="">
                2023 Season Results<span></span>
              </a>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <img className="venue-photo" src="assets/images/UTM-C.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Venue2023;
