import React from "react";

const EndOfSeason = () => {
  return (
    <div className="sec-space bg-light-blue">
      <div className="container">
        <div className="row">
          <div className="sec-heading text-center">
            <h2 className="fs-45 mb-40">Thanks For A Great Season!</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 text-center my-auto">
            <h4 className="venue-text">Thank you to all the:</h4>

            <h2>Judges</h2>
            <h2>Volunteers</h2>
            <h2>Contestants</h2>
            <h2>Parents</h2>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <iframe
              title="2023 Season Wrap-Up"
              src="https://player.vimeo.com/video/837678232?h=f97b8613d0"
              width="640"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EndOfSeason;
