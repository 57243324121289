import React, { useState } from "react";
import roboticsPdf from "../../data/RBG.pdf";
import HTMLPDF from "../../data/Open Programming Challenge Description - HTML (Star).pdf";
import JSPDF from "../../data/Open Programming Challenge Description - JavaScript (Star).pdf";
import PythonPDF from "../../data/Open Programming Challenge Description - Python (Super Nova).pdf";
import UnityPDF from "../../data/Open Programming Challenge Description - Game Jam (Super Nova).pdf";
import Booklet from "../../data/Innovation_Booklet.pdf";

const challengeList = {
  robotics: {
    nebula: roboticsPdf,
    star: roboticsPdf,
  },
  programming: {
    star_html: HTMLPDF,
    star_js: JSPDF,
    super_nova: PythonPDF,
    super_nova_game_jam: UnityPDF,
  },
  innovation: {
    nebula: Booklet,
    star: Booklet,
  },
};

const PastChallenges = () => {
  const [selected, setSelected] = useState("robotics");
  return (
    <div className="sec-space">
      <div className="container">
        <div className="row">
          <div className="sec-heading text-center">
            <h2 className="fs-45 mb-40"> Season Challenges </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="row">
              {Object.keys(challengeList).map((category, i) => {
                return (
                  <button
                    className={`${category}-btn mt-10`}
                    onClick={() => {
                      setSelected(category);
                    }}
                  >
                    {category}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="col-9">
            <div className="challenge-card">
              {console.log(challengeList, selected)}
              {challengeList &&
                selected &&
                Object.keys(challengeList[selected]).map((challenge) => {
                  return (
                    <div className="row mt-10">
                      {" "}
                      <a
                        className="text-theme"
                        target="_blank"
                        rel="noreferrer"
                        href={challengeList[selected][challenge]}
                      >
                        <h3>
                          Check out the 2023{" "}
                          {challenge.split("_").join(" ").toUpperCase()}{" "}
                          challenges
                        </h3>
                        <button className={`${selected}-sm-btn`}>
                          Challenge Details
                        </button>
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PastChallenges;
