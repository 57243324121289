import React, { Component } from "react";
import { ReactComponent as PhoneCall } from "../../svg/phone-call2.svg";
// import { ReactComponent as Mail } from "../../svg/mail2.svg";
// import { Link } from "react-router-dom";

import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/people/Stripe-Competition/100091801277279/",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/stripecompetition",
  },
  { Social: <FaTwitter />, link: "https://twitter.com/stripe_comp" },
];
class Footer extends Component {
  render() {
    return (
      <footer className="sec-space overlay position-relative">
        <div
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "assets/images/mazaa-pattern.png"
            })`,
            backgroundRepeat: "no-repeat",
            width: "250px",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <h4 className="text-white">Pages</h4>
              <p className="fs-18 text-white mb-0">
                <a href="/">Home</a>
              </p>
              <p className="fs-18 text-white mb-0">
                <a href="/about">About Us</a>
              </p>
              <p className="fs-18 text-white mb-0">
                <a href="/2024season">Tournaments</a>
              </p>
            </div>
            <div className="col-lg-3 col-md-3">
              <h4 className="text-white">Streams</h4>
              <p className="fs-18 text-white mb-0">
                <a href="/robotics">Robotics</a>
              </p>
              {/* <p className="fs-18 text-white mb-0">
                <a href="/innovation">Innovation</a>
              </p> */}
              <p className="fs-18 text-white mb-0">
                <a href="/programming">Programming</a>
              </p>
            </div>
            <div className="col-lg-3 col-md-3">
              <h4 className="text-white">Useful Links</h4>
              <p className="fs-18 text-white mb-0">
                <a href="/policies">Policies</a>
              </p>
              <p className="fs-18 text-white mb-0">
                <a href="/faq">FAQ</a>
              </p>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="widget contct-widget">
                <h4 className="widget-title text-white h4 font-weight-bold">
                  Get in Touch
                </h4>
                <div className="conct-info-blk d-flex flex-row">
                  <PhoneCall />
                  <div>
                    <span className=" d-block text-white fs-18">
                      <a href="tel:(647) 477-7410">(647) 477-7410</a>
                    </span>
                  </div>
                </div>
                <br />
                <div className="conct-info-blk d-flex flex-row">
                  <i className="fa fa-envelope-o fs-36"></i>
                  <div>
                    <span className=" d-block text-white fs-18">
                      <a href="mailto:info@stripecompetition.com">
                        info@stripecompetition.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="widget abt-us-widget">
                <h4 className="widget-title text-white h4 font-weight-bold">
                  S.T.R.I.P.E. Competition
                </h4>
                <p className="fs-18 text-white mb-0">
                  Science | Technology | Robotics | Innovation | Programming |
                  Engineering
                </p>
                <div className="folow-us flex-row">
                  <div className="social-share-inner">
                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                      {SocialShare.map((val, i) => (
                        <li key={i}>
                          <a href={`${val.link}`}>{val.Social}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
