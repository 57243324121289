import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const PaymentDecline = ({ location }) => {
  const query = new URLSearchParams(location.search);

  const trans_id = query.get("stripe_trans_id");
  const converge_trans_id = query.get("ssl_txn_id");

  return (
    <>
      <section>
        <div className="error-area pt-40 pb-100">
          <div className="container  ">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 text-center alert alert-info">
                <div>
                  <div>
                    <h1>Uhoh!</h1>
                    <p className="text-danger">
                      Something went wrong. Please call the center to check if
                      your transaction went through.
                    </p>
                  </div>

                  {trans_id && (
                    <div>
                      {" "}
                      <b>S.T.R.I.P.E. Competition Transaction ID: </b> {trans_id}
                    </div>
                  )}
                  {converge_trans_id && (
                    <div>
                      <b>Converge Transaction ID: </b> converge_trans_id
                    </div>
                  )}

                  <Link to={process.env.PUBLIC_URL + "/"} className="error-btn">
                    Back to home page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default PaymentDecline;
