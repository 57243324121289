import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import About from "./component/About/About";
import Ethics from "./component/About/Ethics";
import Programming from "./component/CategoryDescription/Programming";
import Robotics from "./component/CategoryDescription/Robotics";
import Checkout from "./component/Checkout/Checkout";
import PaymentDecline from "./component/Checkout/PaymentDecline";
import PaymentSuccess from "./component/Checkout/PaymentSuccess";
import Gallery from "./component/Gallery/GalleryPage";
import ScoreList from "./component/ScoreEntry/Display/ScoreList";
import LiveBoard from "./component/ScoreEntry/LiveBoard/LiveBoard";
import ScoreboardList from "./component/Scoreboard/ScoreboardList";
import StreamScoreboard from "./component/Scoreboard/StreamScoreboard";
import Winners from "./component/Scoreboard/Winners";
import S2023HomePage from "./component/Season/2023/SummaryPages/S2023HomePage";
import S2024HomePage from "./component/Season/2024/SummaryPages/S2024HomePage";
import Sponsor from "./component/Sponsor/Sponsor";
import TicketsListView from "./component/Tickets/TicketsListView";
import Homepage from "./component/home/Homepage";
import { useAuthContext } from "./context/auth-context";
import FAQ from "./pages/FAQ";
import Login from "./pages/Login";
import TermsAndCopyright from "./pages/TermsAndCopyright";

const StripeRoutes = () => {
  let context = useAuthContext();
  return (
    <BrowserRouter basename={"/"}>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        s
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/About`}
          component={About}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/Ethics`}
          component={Ethics}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/Sponsor`}
          component={Sponsor}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/Programming`}
          component={Programming}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/Robotics`}
          component={Robotics}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/tickets`}
          component={TicketsListView}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/checkout`}
          component={Checkout}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/live-board`}
          component={LiveBoard}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/payment-success`}
          component={PaymentSuccess}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/payment-decline`}
          component={PaymentDecline}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/policies`}
          component={TermsAndCopyright}
        />
        <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/robotics-scoreboard`}
          component={StreamScoreboard}
        />{" "}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/programming-scoreboard`}
          component={StreamScoreboard}
        />
        {context.isLoggedIn && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/enter-scores`}
            component={ScoreList}
          />
        )}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/score`}
          component={ScoreboardList}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/2023winners`}
          component={Winners}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/gallery`}
          component={Gallery}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/2023season`}
          component={S2023HomePage}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/2024season`}
          component={S2024HomePage}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} component={Homepage} />
      </Switch>
    </BrowserRouter>
  );
};

export default StripeRoutes;
