import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Card from "react-bootstrap/Card";
import moment from "moment/moment";

const TicketList = ({ data, event, streams }) => {
  let history = useHistory();

  return (
    <>
      <div className="ticket-header mb-40">
        <h3 className="">{event.name}</h3>
        <h4>
          Venue: <b>{event.venue}</b>
        </h4>
        <h4>
          Date:{" "}
          <b>
            {event.startdate === event.enddate
              ? moment(event.startdate, "YYYY-MM-DD").format("DD MMMM YYYY")
              : moment(event.startdate, "YYYY-MM-DD").format("DD MMMM YYYY") +
                " - " +
                moment(event.enddate, "YYYY-MM-DD").format("DD MMMM YYYY")}
          </b>{" "}
          {console.log(event)}@{" "}
          <b>
            {moment(event.start_time, "HH:mm:ss").format("hh:mm a")} - {moment(event.end_time, "HH:mm:ss").format("hh:mm a")}
          </b>
        </h4>
      </div>
      <div className="event-list bg-light-yellow">
        {streams &&
          streams
            .filter((c) => {
              return c.included == 1;
            })
            .map((p, index) => {
              return (
                <div key={index} className="row mt-10">
                  <Card className="event-card">
                    <Card.Body>
                      <Card.Title>{p.name}</Card.Title>
                      <Card.Text>
                        {p.name === "Programming" ? (
                          <>
                            <p>
                              This challenge is recommended for competitiors
                              with experience in programming/coding langauges
                              like:
                            </p>
                            <ul>
                              <li>Python</li>
                              <li>HTML</li>
                              <li>JavaScript</li>
                              <li>Scratch</li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <p>
                              This challenge is recommended for competitiors
                              with experience with robots like:
                            </p>
                            <ul>
                              <li>Lego Mindstorm EV3</li>
                              <li>Lego WeDo 2.0</li>
                              <li>Lego Spike Prime</li>
                            </ul>
                          </>
                        )}
                      </Card.Text>
                      {data
                        .filter((value, index) => {
                          return value.stream_name == p.name;
                        })
                        .map((ticketData, tdi) => {
                          return (
                            <a
                              key={tdi}
                              className="col-md-2 theme-btn-secondary btn-15"
                              href={`/checkout?event_id=${ticketData.event_stream_id}`}
                            >
                              ${ticketData.fee.toFixed(2)}
                            </a>
                          );
                        })}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
      </div>
    </>
  );
};
export default TicketList;
