import React from "react";
import MetaTags from "react-meta-tags";
import { ReactComponent as CreativeChallenge } from "../../svg/creative_challenge.svg";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";
import ChallengeStrip from "./CategoryDetails/ChallengeStrip";

const Programming = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Programming Challenge | S.T.R.I.P.E. Competition</title>
        <meta
          name="description"
          content="Join the S.T.R.I.P.E. Competition Programming Challenge for students ages 6 - 18.
            In the programming languages Python, Scratch, HTML, and Javascript"
        />
        <link
          rel="canonical"
          href="https://www.stripecompetition.com/Programming"
        />
      </MetaTags>
      <Header />
      {/* <!-- Breadcrumbs --> */}
      <Banner title="Programming" />
      <main>
        <section className="pt-60 pb-30">
          <div className="container">
            <p className="fs-18">
              Everything from laptops to toothbrushes are programmed, and
              students who can successfully solve problems with code have the
              opportunity to hone their problem-solving abilities in new ways.
              At STRIPE Competition, we use our programming challenges to push
              students to their limits and show them how much they are capable
              of. Students in the programming category compete virtually to
              solve real life problems based on our theme.
            </p>
          </div>
        </section>

        <section>
          <div className="container category-card category-bg-1">
            <div className="row">
              <ChallengeStrip
                icon={<CreativeChallenge />}
                category_name="Open Programming Challenge - Nebula"
                age="6-9"
                team_size="1 member"
                third_title="Language"
                third_text="Scratch"
              />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="assets/images/programming_nebula.png"
                  alt="Nebula Programming"
                  width="100%"
                />
              </div>
              <div className="col-lg-8">
                <div className="recipe-detail-content">
                  <p className="fs-18">
                    The Open Programming Challenge is an open challenge for any
                    students between the ages of 6-9. Students will choose to
                    create games or animations using Scratch based on the
                    season’s theme of Alien Planet. The students will showcase
                    what they learned that season. The students will be ranked
                    based on their creativity, knowledge, and originality.
                  </p>
                  <p className="fs-18">
                    Students should also make sure they use the Scratch website
                    to complete the challenges and save it to their account.{" "}
                    <b>
                      Students will need to make sure they bring their own
                      device on the day of the competition
                    </b>
                  </p>
                  <br />
                  <a className="theme-btn-secondary" href="/2024season">
                    Register<span></span>
                  </a>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container category-card category-bg-2">
            <div className="row">
              <ChallengeStrip
                icon={<CreativeChallenge />}
                category_name="Open Programming Challenge - Star"
                age="10-14"
                team_size="1 member"
                third_title="Language"
                third_text="HTML | HTML & JS"
              />
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="recipe-detail-content">
                  <p className="fs-18">
                    The Open Programming Challenge is an open challenge for any
                    students between the ages of 10-14. Students will create
                    simple or interactive websites based on the season’s theme.
                    The students will showcase what they learned that season.
                    The students will be ranked based on their creativity,
                    knowledge, and originality.
                  </p>

                  <p className="fs-18">
                    <b>
                      Students will need to make sure they bring their own
                      device on the day of the competition
                    </b>
                  </p>
                  <br />
                  <a className="theme-btn-secondary" href="/2024season">
                    Register<span></span>
                  </a>

                  <br />
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  src="assets/images/programming_star.png"
                  alt="Star Programming"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container category-card category-bg-1">
            <div className="row">
              <ChallengeStrip
                icon={<CreativeChallenge />}
                category_name="Hackathon Challenge - Super Nova"
                age="12-18"
                team_size="1 member"
                third_title="Language"
                third_text="Python"
              />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="assets/images/programming_super_nova.png"
                  alt="Super Nova Programming"
                  width="100%"
                />
              </div>
              <div className="col-lg-8">
                <div className="recipe-detail-content">
                  <p className="fs-18">
                    The Hackathon Challenge is a programming challenge for any
                    students between the ages of 12-18. Students will be given
                    sets of programming challenges. The students will compete to
                    see who can complete the most challenges successfully.
                  </p>

                  <p className="fs-18">
                    <b>
                      Students will need to make sure they bring their own
                      device on the day of the competition
                    </b>
                  </p>

                  <br />
                  <a className="theme-btn-secondary" href="/2024season">
                    Register<span></span>
                  </a>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
};
export default Programming;
